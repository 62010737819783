<template>
    <v-container fluid style="background-color: #F4F4F5 !important; height: 100%;" class="pa-0 ma-0">
        <v-alert color="#79A2BD" dark class="text-center">
            <strong>{{ get_selected_company.company_name }}</strong>
        </v-alert>
        <v-row justify="center" align="center">
            <v-card elevation="2" width="45%" class="mt-5">
                <v-card-title style="font-size: 20px;">
                    Supplier setup
                </v-card-title>
                <v-card-subtitle>
                    General supplier information lives here
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field outlined label="Supplier Name *" dense
                                prepend-inner-icon="mdi-tooltip-text-outline" v-model="c.supplier_name">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete v-model="c.service_type" label="choose a service type *"
                                prepend-inner-icon="mdi-tooltip-text-outline" :items="get_supplier_type" item-value="id"
                                item-text="name" outlined dense chips>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <label>Industry Rating:</label>
                            <strong v-if="c.rating != null"> {{ c.rating }} Star(s)</strong>
                            <v-rating empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full"
                                length="5" size="32" v-model="c.rating" color="warning" half-increments></v-rating>
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="c.currency_id" :items="get_currencies" item-text="code" item-value="id"
                                outlined label="Currency" dense>
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field dense outlined prepend-inner-icon="mdi-at" label="Booking email"
                                v-model="c.email">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="number" v-model="c.phone" dense label="Phone" outlined
                                prepend-inner-icon="mdi-phone-outline">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="number" v-model="c.mobile" dense label="Mobile" outlined
                                prepend-inner-icon="mdi-cellphone">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="number" v-model="c.freephone" dense label="Freephone" outlined
                                prepend-inner-icon="mdi-phone-outline">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="number" v-model="c.other" dense label="Other" outlined
                                prepend-inner-icon="mdi-phone-outline">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field dense outlined v-model="c.website" label="website"
                                prepend-inner-icon="mdi-web">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field dense outlined v-model="c.notes" label="notes"
                                prepend-inner-icon="mdi-tooltip-text-outline">
                            </v-text-field>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12">
                            <strong style="font-size: 20px;">Location</strong>
                            <v-text-field dense outlined prepend-inner-icon="mdi-map-marker-outline"
                                @click="dialog_address_fn" :placeholder="`${c.address} ${c.city} ${c.postcode}`">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <strong style="font-size: 20px;">Tags</strong>
                            <v-text-field dense outlined v-model="for_tag" label="Tags"
                                prepend-inner-icon="mdi-tooltip-text-outline" @keyup.enter="append_tags">
                            </v-text-field>
                        </v-col>
                        <v-row>
                            <v-col cols="3" v-for="(tag, tagindex) in c.tags" :key="tagindex" class="ml-10">
                                <strong>{{ tag }}</strong>
                                <v-icon style="cursor:pointer;" @click="remove_element(tagindex)">mdi-delete</v-icon>
                            </v-col>
                        </v-row>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#588BAD" dark small rounded @click="save_supplier" class="pl-10 pr-10 pt-5 pb-5">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
        <v-row justify="center">
            <v-dialog max-width="620" v-model="dialog_address" persistent>
                <v-card>
                    <v-card-title style="font-size: 16px;">
                        <small>Edit location</small>
                        <v-spacer></v-spacer>
                        <v-icon style="cursor: pointer;" @click="close_dialog_address">mdi-close-box-outline</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field dense outlined v-model="c.location" label="Location name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense outlined v-model="c.address" label="Address">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense outlined v-model="c.city" label="City">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense outlined label="Postcode" v-model="c.postcode">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense outlined label="Country" v-model="c.country">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense outlined label="Latitude" v-model="c.latitude">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense outlined label="Longitude" v-model="c.longitude">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn block color="#588BAD" dark small rounded @click="save_location">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        c: {
            supplier_name: null,
            service_type: null,
            currency_id: null,
            rating: null,
            email: null,
            phone: null,
            mobile: null,
            freephone: null,
            other: null,
            website: null,
            notes: null,
            location: null,
            address: null,
            city: null,
            postcode: null,
            country: null,
            latitude: null,
            longitude: null,
            tags: []
        },
        whole_location: null,
        dialog_address: false,
        for_tag: null
    }),
    mounted() {
        this.$store.dispatch('supplier/fetch_supplier_type')
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_supplier_type: 'supplier/get_supplier_type',
            get_currencies: 'itineraryV2/get_currencies',
        })
    },
    methods: {
        dialog_address_fn() {
            this.dialog_address = true
        },
        close_dialog_address() {
            this.dialog_address = false
        },
        save_location() {
            this.whole_location = this.c.address + ', ' + this.c.city + ' ' + this.c.postcode
            this.close_dialog_address()
        },
        append_tags() {
            const check_if_exist = this.c.tags.includes(this.for_tag.toLowerCase())
            if (check_if_exist) {
                this.$toast.error(`${this.for_tag} exist on tags`)
                return
            }
            this.c.tags.push(this.for_tag.toLowerCase())
            this.for_tag = null
        },
        remove_element(tagindex) {
            this.c.tags.splice(tagindex, 1)
        },
        async save_supplier() {
            await this.$axios.post('suppliers/add_supplier', {
                supplier_name: this.c.supplier_name,
                service_type_id: this.c.service_type,
                currency_id: this.c.currency_id,
                industry_rating: this.c.rating,
                email: this.c.email,
                phone: this.c.phone,
                mobile: this.c.mobile,
                free_phone: this.c.freephone,
                others: this.c.other,
                website: this.c.website,
                notes: this.c.notes,
                location_name: this.c.location,
                address: this.whole_location,
                latitude: this.c.latitude,
                longitude: this.c.longitude,
                tags: this.c.tags,
                company_id: this.get_selected_company.id
            })
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.c = {}
                        this.whole_location = null
                        this.for_tag = null
                        this.$router.push({ name: 'Suppliers' })
                        //this.$store.dispatch('supplier/set_suppliers', data.data)
                    } else {
                        this.$toast.error(data.message)
                    }
                })
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>
<template>
    <v-container fluid style="max-width:1200px;">
        <v-card elevation="0">
            <v-card-title>
                <v-btn @click="refreshBookings(true)" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-refresh</v-icon>
                    Refresh
                </v-btn>
                <v-spacer />
                <v-btn @click="openCreatePaymentDialog()" rounded color="primary" class="mx-2">
                    Create Payment
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :items="payments" :headers="payment_headers" :sort-by.sync="sort_by"
                    :sort-desc.sync="sort_desc">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                {{ $dayjs(item.created_at).format('MMM D, YYYY') }}
                                <br>
                                {{ $dayjs(item.created_at).format('h:mm A') }}
                            </td>
                            <td>

                                <v-tooltip v-if="item.paid_by_traveller" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <label v-bind="attrs" v-on="on">
                                            {{
                                                item.paid_by_traveller.first_name + ' '
                                                + item.paid_by_traveller.last_name
                                            }}
                                        </label>
                                    </template>
                                    <small>TID: {{ item.paid_by_traveller_id }}</small>
                                </v-tooltip>
                                <v-tooltip v-else-if="item.paid_by_user" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <label v-bind="attrs" v-on="on">
                                            Payment Taken By <br>
                                            {{ item.paid_by_user.name }}
                                        </label>
                                    </template>
                                    <small>UID: {{ item.paid_by_user_id }}</small>
                                </v-tooltip>
                            </td>
                            <td>
                                {{ item.type.name }}
                            </td>
                            <td>
                                {{ item.method }}
                            </td>
                            <td>
                                {{ (item.currency ? item.currency.symbol : '') + item.amount.toFixed(2) }}
                            </td>
                            <td>
                                {{ (item.currency ? item.currency.symbol : '') + item.surcharge.toFixed(2) }}
                            </td>
                            <td>
                                {{ (item.currency ? item.currency.symbol : '') + item.tip_amount.toFixed(2) }}
                            </td>
                            <td>
                                {{ (item.currency ? item.currency.symbol : '') + item.total_amount.toFixed(2) }}
                            </td>
                            <td>
                                <div class="d-flex">
                                    <v-btn @click="openUpdatePaymentDialog(item)" icon>
                                        <v-icon>mdi-pen</v-icon>
                                    </v-btn>
                                    <v-btn @click="openDeletePaymentDialog(item)" v-if="item.paid_by_user" icon>
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <v-row>
                    <v-col cols="6">
                        Total Itinerary Amount Paid:
                        {{ (itinerary.currency ? itinerary.currency.symbol : '') + totalPaid.toFixed(2) }}
                    </v-col>
                    <v-col cols="6">
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <CreatePaymentDialog :create_payment_dialog="create_payment_dialog" :itinerary="itinerary"
            :payment_types="payment_types" @close="closeCreatePaymentDialog" @createPayment="createPayment" />
        <UpdatePaymentDialog v-if="selected_payment" :update_payment_dialog="update_payment_dialog"
            :itinerary="itinerary" :selected_payment="selected_payment" :payment_types="payment_types"
            @close="closeUpdatePaymentDialog" @updatePayment="updatePayment" />
        <DeletePaymentDialog :delete_payment_dialog="delete_payment_dialog" @close="closeDeletePaymentDialog"
            @deletePayment="deletePayment" />
    </v-container>
</template>

<script>
import CreatePaymentDialog from './dialogs/CreatePaymentDialog.vue'
import UpdatePaymentDialog from './dialogs/UpdatePaymentDialog.vue'
import DeletePaymentDialog from './dialogs/DeletePaymentDialog.vue'
export default {
    components: {
        CreatePaymentDialog, UpdatePaymentDialog, DeletePaymentDialog,
    },
    props: [
        'itinerary', 'net', 'gross',
    ],
    data() {
        return {
            payments: [],
            payment_types: [],
            payment_headers: [
                { text: 'Date', value: 'created_at' },
                { text: 'Paid By', value: 'paid_by_traveller_id' },
                { text: 'Type', value: 'payment_type_id' },
                { text: 'Payment Method', value: 'method' },
                { text: 'Itinerary Amount', value: 'amount', width: '100px' },
                { text: 'Surcharge', value: 'surcharge', width: '100px' },
                { text: 'Tip to Agent', value: 'tip_amount', width: '100px' },
                { text: 'Total Amount', value: 'total_amount', width: '100px' },
                { text: '' },
            ],
            sort_by: 'created_at',
            sort_desc: true,
            create_payment_dialog: false,
            update_payment_dialog: false,
            delete_payment_dialog: false,
            selected_payment: null,
        }
    },
    async mounted() {
        this.getPayments()
        this.getPaymentTypes()
    },
    created() {
    },
    computed: {
        totalPaid() {
            return this.payments.reduce((sum, payment) => sum + payment.amount, 0)
        },
        balanceRemaining() {
            return this.gross - this.totalPaid
        },
    },
    methods: {
        async refreshBookings(message = false) {
            this.$emit('refreshBookings')
            if (message) {
                this.$toast.info('Refreshing...')
            }
        },
        async getPayments() {
            let payload = {
                itinerary_id: this.itinerary.id,
            }
            await this.$axios.get('v2/payments/get_payments', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.payments = data.data
                    }
                })
        },
        async getPaymentTypes() {
            let payload = {
                is_public: 1,
            }
            await this.$axios.get('v2/payments/get_payment_types', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.payment_types = data.data
                    }
                })
        },
        openCreatePaymentDialog() {
            this.create_payment_dialog = true
        },
        closeCreatePaymentDialog() {
            this.create_payment_dialog = false
        },
        async createPayment(new_payment) {
            await this.$axios.post('v2/payments/create_payment', new_payment)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getPayments()
                        this.closeCreatePaymentDialog()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openUpdatePaymentDialog(item) {
            this.update_payment_dialog = true
            this.selected_payment = item
        },
        closeUpdatePaymentDialog() {
            this.update_payment_dialog = false
            this.selected_payment = null
        },
        async updatePayment(updated_payment) {
            await this.$axios.post(`v2/payments/update_payment/${updated_payment.id}`, updated_payment)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getPayments()
                        this.closeUpdatePaymentDialog()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeletePaymentDialog(item) {
            this.delete_payment_dialog = true
            this.selected_payment = item
        },
        closeDeletePaymentDialog() {
            this.delete_payment_dialog = false
            this.selected_payment = null
        },
        async deletePayment() {
            await this.$axios.delete(`v2/payments/delete_payment/${this.selected_payment.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getPayments()
                        this.closeDeletePaymentDialog()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
    },
}
</script>

<style scoped></style>
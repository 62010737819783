<template>
    <v-dialog :value="update_company_dialog" width="800" persistent>
        <v-card v-if="selected_company">
            <v-card-title>
                {{ selected_company.company_name }}
                <v-spacer></v-spacer>
                <v-btn @click="close()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.company_name" label="Company Name" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.website" label="Website" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.email" label="Reservations Email" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.accounts_email" label="Accounts Email" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-file-input v-model="logo_file" label="Primary Logo" outlined hide-details dense />
                        <div v-if="selected_company.company_logo" class="mt-5">
                            <v-img :src="selected_company.company_logo" class="mx-auto" contain max-height="150"
                                max-width="250" />
                        </div>
                        <label v-else>No image set</label>
                    </v-col>
                    <v-col cols="6">
                        <v-file-input v-model="secondary_file" label="Secondary Logo" outlined hide-details dense />
                        <div v-if="selected_company.secondary_logo" class="mt-5">
                            <v-img :src="selected_company.secondary_logo" class="mx-auto" contain max-height="150"
                                max-width="250" />
                        </div>
                        <label v-else>No image set</label>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="updated_company.cover_title" label="Itinerary Cover Title" dense
                            outlined />
                    </v-col>
                    <v-col cols="4">
                        Primary Color
                        <v-color-picker v-model="updated_company.primary_color" mode="hexa"></v-color-picker>
                    </v-col>
                    <v-col cols="4">
                        Secondary Color
                        <v-color-picker v-model="updated_company.secondary_color" mode="hexa"></v-color-picker>
                    </v-col>
                    <v-col cols="4">
                        Tertiary Color
                        <v-color-picker v-model="updated_company.tertiary_color" mode="hexa"></v-color-picker>
                    </v-col>
                    <v-col cols="6">
                        <v-file-input v-model="cover_file" label="Itinerary Cover Image" outlined hide-details dense />
                    </v-col>
                    <v-col cols="6">
                        <div v-if="selected_company.cover_image" class="mt-5">
                            <v-img :src="selected_company.cover_image" class="mx-auto" contain max-height="150"
                                max-width="250" />
                        </div>
                        <label v-else>No image set</label>
                    </v-col>
                    <v-col cols="12">
                        Itinerary Cover Description
                        <wysiwyg v-model="updated_company.cover_description" />
                    </v-col>
                    <v-col cols="12">
                        Itinerary Cover Details
                        <wysiwyg v-model="updated_company.cover_details" />
                    </v-col>
                    <v-col cols="12" class="my-3">
                        Client App Settings
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="updated_company.login_enabled"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" label="Login Enabled" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="updated_company.payment_enabled"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" label="Payment Enabled" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.payment_gateway"
                            label="Payment Gateway (sagepay, stripe, authipay)" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="updated_company.payment_mode" :items="['test', 'live']"
                            label="Payment Enabled" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.test_key" label="Test Key" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.live_key" label="Live Key" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="updated_company.deposit_enabled"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" label="Deposit Payment Enabled" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="updated_company.partial_enabled"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" label="Partial Payment Enabled" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.surcharge_percent" type="number" label="Surcharge Percent" dense outlined />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.tip_amounts" label="Tip Amounts (15,30,50)" dense outlined />
                    </v-col>
                    <v-col cols="12">
                        Payment FAQs
                        <wysiwyg v-model="updated_company.payment_faqs" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" text rounded>Cancel</v-btn>
                <v-btn @click="updateCompany()" color="primary" class="px-5" rounded>
                    Update
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        'update_company_dialog', 'selected_company'
    ],
    data() {
        return {
            updated_company: { ...this.selected_company },
            logo_file: null,
            secondary_file: null,
            cover_file: null,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        updateCompany() {
            let payload = {
                ...this.updated_company,
                logo_file: this.logo_file,
                secondary_file: this.secondary_file,
                cover_file: this.cover_file,
            }
            this.$emit('updateCompany', payload)
        },
    }
}
</script>
<template>
  <v-container fluid style="background-color: #f4f4f5 !important">
    <v-row class="mt-5" justify="center" align="center">
      <v-card width="820" elevation="2">
        <v-card-title class="text-small">
          <small><strong>Update Product</strong></small>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Name *" v-model="product.name" outlined dense />
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="product.supplier_product_type_id" :items="get_supplier_product_type"
                item-value="id" item-text="name" label="Type *" outlined dense chips />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="product.code" label="Code" outlined dense />
            </v-col>
            <v-col cols="12">
              <label>Description</label>
              <wysiwyg v-model="product.description" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="updateProduct()" color="primary" block>Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  props: ['supplier', 'product'],
  data: () => ({
    f: {
      supplier_product_type_id: null,
      name: null,
      code: null,
      description: null,
    },
  }),
  mounted() {
    this.$store.dispatch('supplier/fetch_supplier_product_type')
  },
  created() {
  },
  computed: {
    ...mapGetters({
      get_supplier_product_type: 'supplier/get_supplier_product_type',
    }),
  },
  methods: {
    async updateProduct() {
      let payload = {
        name: this.product.name,
        code: this.product.code,
        supplier_product_type_id: this.product.supplier_product_type_id,
        description: this.product.description,
      }
      console.log(payload)
      await this.$axios.post(`v2/suppliers/update_product/${this.product.id}`, payload)
        .then(({data}) => {
          if(data.response) {
            this.$toast.success(data.message)
            this.$emit('getSupplier')
          }
          else {
            this.$toast.error(data.message)
          }
        })
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss"></style>

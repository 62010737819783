<template>
  <v-container fluid style="background-color: #f4f4f5 !important">
    <v-row class="mt-5" justify="center" align="center" style="margin-bottom:200px;">
      <v-card width="820" elevation="2">
        <v-card-title class="text-small">
          <small><strong>Update Supplier</strong></small>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-1">
            <v-col cols="6">
              <v-text-field v-model="supplier.supplier_name" label="Supplier name *" outlined dense>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="supplier.service_type_id" label="choose a service type *"
                prepend-inner-icon="mdi-tooltip-text-outline" :items="get_supplier_type" item-value="id"
                item-text="name" outlined dense chips>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <label>Industry Rating:</label>
              <strong v-if="supplier.industry_rating != null">
                {{ supplier.industry_rating }} Star(s)</strong>
              <v-rating empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full" length="5"
                size="32" v-model="supplier.industry_rating" color="warning" half-increments></v-rating>
            </v-col>
            <v-col cols="6">
              <v-select v-model="supplier.currency_id" :items="get_currencies" item-text="code" item-value="id" outlined
                label="Currency" dense>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field dense outlined prepend-inner-icon="mdi-at" label="Booking email" v-model="supplier.email">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field type="number" v-model="supplier.phone" dense label="Phone" outlined
                prepend-inner-icon="mdi-phone-outline">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field type="number" v-model="supplier.mobile" dense label="Mobile" outlined
                prepend-inner-icon="mdi-cellphone">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field type="number" v-model="supplier.free_phone" dense label="Freephone" outlined
                prepend-inner-icon="mdi-phone-outline">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field type="number" v-model="supplier.others" dense label="Other" outlined
                prepend-inner-icon="mdi-phone-outline">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field dense outlined v-model="supplier.website" label="website" prepend-inner-icon="mdi-web">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              Warning Note
              <wysiwyg dense outlined v-model="supplier.warning_note" />
            </v-col>
            <v-col cols="12">
              <v-textarea dense outlined v-model="supplier.notes" label="notes"
                prepend-inner-icon="mdi-tooltip-text-outline">
              </v-textarea>
              <div class="mb-5"><input type="checkbox" v-model="supplier.auto_add_notes" /> Auto add notes to
                vouchers/bookings</div>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <strong style="font-size: 20px">Location</strong>
              <v-text-field dense outlined prepend-inner-icon="mdi-map-marker-outline" v-model="supplier.address">
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <strong style="font-size: 20px">Town</strong>
              <v-text-field dense outlined prepend-inner-icon="mdi-map-marker-outline" v-model="supplier.town">
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <strong style="font-size: 20px">County</strong>
              <v-text-field dense outlined prepend-inner-icon="mdi-map-marker-outline" v-model="supplier.county">
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <strong style="font-size: 20px">Province</strong>
              <v-text-field dense outlined prepend-inner-icon="mdi-map-marker-outline" v-model="supplier.province">
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <strong style="font-size: 20px">Country</strong>
              <v-text-field dense outlined prepend-inner-icon="mdi-map-marker-outline" v-model="supplier.country">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <strong style="font-size: 20px">Tags</strong>
              <v-text-field dense outlined v-model="tags" label="Tags" prepend-inner-icon="mdi-tooltip-text-outline"
                @keyup.enter="add_tags">
              </v-text-field>
            </v-col>
            <v-row>
              <v-col cols="3" v-for="(tag, tagindex) in supplier.tags" :key="tagindex" class="ml-10">
                <strong>{{ tag }}</strong>
                <v-icon style="cursor: pointer" @click="remove_element(tagindex)">mdi-delete</v-icon>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#588BAD" dark small rounded @click="saveSupplier()" class="pl-10 pr-10 pt-5 pb-5">
            Save
          </v-btn>
        </v-card-actions>
        <!-- <v-card elevation="2" class="mt-15">
          <v-card-title style="font-size: 20px">
            Contacts
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on" rounded style="
                    text-transform: none !important;
                    position: relative;
                    top: 15px;
                  " class="pt-5 pb-5">
                  Add contact
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
<v-list>
  <v-list-item>
    <v-list-item-title>Add existing contacts</v-list-item-title>
  </v-list-item>
  <v-list-item @click="
                  $router.push({
                    name: '/add_contacts_in_supplier',
                    query: {
                      title: 'Add new contact',
                      fields: [
                        {
                          label: 'First name*',
                          type: 'text',
                          col: 12,
                          ttype: 'text-field',
                          icon: 'mdi-tooltip-text-outline',
                        },
                        {
                          label: 'Last name*',
                          type: 'text',
                          col: 12,
                          ttype: 'text-field',
                          icon: 'mdi-tooltip-text-outline',
                        },
                        {
                          label: 'Email',
                          type: 'email',
                          col: 12,
                          ttype: 'text-field',
                          icon: 'mdi-tooltip-text-outline',
                        },
                      ],
                      buttons: [
                        {
                          icon: 'mdi-arrow-left-circle',
                          action: '/supplier-setup',
                        },
                      ],
                      buttons_action: [
                        {
                          icon: 'mdi-content-save-outline',
                          label: 'Add contact',
                          style: 'textTransform: none !important',
                          class: 'pl-10 pr-10',
                          color: 'primary',
                          action: 'save',
                        },
                      ],
                    },
                  })
                  ">
    <v-list-item-title>Create new</v-list-item-title>
  </v-list-item>
</v-list>
</v-menu>
</v-card-title>
<v-card-subtitle>
  Add contacts that work at this supplier organisation
</v-card-subtitle>
<v-card-text>
  <v-list-item two-line v-for="(contact, contactindex) in supplier.contacts" :key="contactindex">
    <v-list-item-content>
      <v-list-item-title>
        {{ contact.first_name }} {{ contact.last_name }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ contact.email }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon>
        <v-icon class="mr-3" color="grey lighten-1">mdi-account-eye-outline</v-icon>
        <v-icon color="grey lighten-1" @click="archive_contact(contact)">mdi-delete-outline</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</v-card-text>
</v-card> -->
      </v-card>
      <v-dialog v-model="change_location_dialog" persistent max-width="720">
        <v-card style="border-radius: 8px">
          <v-card-title style="font-size: 20px">
            <small>Update location</small>
            <v-spacer />
            <v-icon @click="close_dialog_edit_location">
              mdi-close-box-outline
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field dense outlined v-model="supplier.location_name">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field dense outlined v-model="e.address" label="Address">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field dense outlined v-model="e.city" label="City">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field dense outlined v-model="e.postcode" label="Postcode">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field dense outlined v-model="e.country" label="Country">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field dense outlined v-model="supplier.latitude">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field dense outlined v-model="supplier.longitude">
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn block dark color="#588BAD" small rounded @click="update_location">
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <DialogDelete :value="show_warning_dialog_delete" :title="dialog_delete.title" :body="dialog_delete.body"
      :details="`${this.get_contact_to_archive.first_name} ${this.get_contact_to_archive.last_name}?`" width="470"
      action="remove" color="red" @close_dialog="close_dialog" @proceed_archive="proceed_archive" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DialogDelete from '../../../../dialog/DialogComponent.vue';
export default {
  components: {
    DialogDelete,
  },
  props: ['supplier'],
  data: () => ({
    for_tag: null,
    show_warning_dialog_delete: false,
    dialog_delete: {
      title: 'Youre about to remove a supplier contact',
      body: {
        label: 'Are you sure you want to remove',
      },
    },
    change_location_dialog: false,
    e: {
      address: null,
      city: null,
      postcode: null,
      country: null,
    },
    tags: null,
  }),
  async mounted() {
  },
  created() { },
  computed: {
    ...mapGetters({
      get_supplier_type: 'supplier/get_supplier_type',
      get_contact_to_archive: 'contact/get_contact_to_archive',
      get_selected_company: 'auth/get_selected_company',
      get_currencies: 'itineraryV2/get_currencies',
    }),
  },
  methods: {
    archive_contact(data) {
      this.$store.dispatch('contact/set_contact_to_archive', data);
      this.show_warning_dialog_delete = true;
    },
    close_dialog() {
      this.show_warning_dialog_delete = false;
      this.change_location_dialog = false;
    },
    async saveSupplier() {
      await this.$axios.patch('/suppliers/update_supplier', this.supplier)
        .then(({ data }) => {
          if (data.response) {
            this.$toast.success('Updated supplier successfully')
            // this.$store.dispatch('supplier/set_supplier_setup', data.supplier_setup)
          }
        });
    },
    async proceed_archive() {
      await this.$axios
        .delete(
          `/contacts/archive/${this.get_contact_to_archive.id}/${this.get_selected_company.id}`
        )
        .then(({ data }) => {
          if (data.response) {
            // this.$store.dispatch(
            //   'supplier/fetch_supplier',
            //   this.get_selected_company.id
            // );
            // this.$store.dispatch(
            //   'supplier/set_supplier_setup_company',
            //   data.data
            // );
            this.close_dialog();
          }
        });
    },
    async change_supplier_type(id) {
      await this.$axios
        .patch('/suppliers/change_supplier_type', {
          supplier_id: id,
          supplier_type: this.supplier.get_supplier_type.id,
          company_id: this.get_selected_company.id,
        })
        .then(({ data }) => {
          if (data.response) {
            // this.$store.dispatch(
            //   'supplier/set_supplier_setup',
            //   data.supplier_setup
            // );
          }
        });
    },
    change_location() {
      this.change_location_dialog = true;
    },
    close_dialog_edit_location() {
      this.change_location_dialog = false;
      this.e = {};
    },
    async update_new_locations() {
      let payload = {
        company_id: this.get_selected_company.id,
        supplier_id: this.supplier.id,
        town: this.supplier.town,
        county: this.supplier.county,
        province: this.supplier.province,
        country: this.supplier.country,
      }
      await this.$axios.patch('suppliers/update_new_locations', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$toast.success('Saved location successfully')
          }
        })
    },
    async update_location() {
      await this.$axios
        .patch('suppliers/change_location', {
          address:
            this.e.address +
            ' ' +
            this.e.city +
            ' ' +
            this.e.country +
            ' ' +
            this.e.postcode,
          latitude: this.supplier.latitude,
          longitude: this.supplier.longitude,
          location_name: this.supplier.location_name,
          supplier_id: this.supplier.id,
          company_id: this.get_selected_company.id,
        })
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch(
              'supplier/set_supplier_setup',
              data.supplier_setup
            );
            this.close_dialog_edit_location();
          }
        });
    },
    async add_tags() {
      const check_if_exist = this.supplier.tags.includes(
        this.tags.toLowerCase()
      );
      if (check_if_exist) {
        this.$toast.success(`${this.tags} exist on tags`)
        return
      }
      this.supplier.tags.push(this.tags.toLowerCase());
      this.tags = null;
      await this.$axios
        .patch('/suppliers/update_tags', {
          tags: this.supplier.tags,
          supplier_id: this.supplier.id,
          company_id: this.get_selected_company.id,
        })
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch(
              'supplier/set_supplier_setup',
              data.supplier_setup
            );
          }
        });
    },
    async remove_element(tagindex) {
      this.supplier.tags.splice(tagindex, 1);
      await this.$axios
        .patch('/suppliers/update_tags', {
          tags: this.supplier.tags,
          supplier_id: this.supplier.id,
          company_id: this.get_selected_company.id,
        })
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch(
              'supplier/set_supplier_setup',
              data.supplier_setup
            );
          }
        });
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>

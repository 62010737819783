<template>
    <v-dialog :value="delete_payment_dialog" persistent max-width="500">
        <v-card>
            <v-card-title class="d-flex">
                Are you sure you want to delete this payment?
                <v-spacer />
            </v-card-title>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Cancel
                </v-btn>
                <v-btn @click="deletePayment()" rounded color="red" dark class="px-5">
                    Delete
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        'delete_payment_dialog',
    ],
    methods: {
        close() {
            this.$emit('close')
        },
        deletePayment() {
            this.$emit('deletePayment')
        },
    },
}
</script>
<template>
    <div>
        <div class="header">
            <h3>{{ get_selected_company.company_name }}</h3>
        </div>
        <div class="card">
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <div class="card-header">Bookings</div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="card-header-items">
                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" v-bind="attrs" v-on="on" class="ml-2 px-5" rounded
                                    :disabled="selected_bookings.length == 0">
                                    Send {{ selected_bookings.length }} payment notification<label
                                        v-if="selected_bookings.length > 1">s</label>
                                    <v-icon>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="openSupplierEmailDialog('payment')">
                                    <v-list-item-title>
                                        Send Payment Notification
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="px-3">
                    <v-text-field label="Supplier Name" prepend-icon="mdi-magnify" v-model="supplier_name"
                        @keyup.enter="searchBookings()"></v-text-field>
                </v-col>
                <v-col class="px-3">
                    <v-text-field label="Supplier Ref" prepend-icon="mdi-magnify" v-model="booking_reference_number"
                        @keyup.enter="searchBookings()"></v-text-field>
                </v-col>
                <v-col class="px-3">
                    <v-text-field label="Booking ID" prepend-icon="mdi-magnify" v-model="booking_id"
                        @keyup.enter="searchBookings()"></v-text-field>
                </v-col>
                <!-- <v-col class="px-3">
                    <v-text-field label="Itinerary Name" prepend-icon="mdi-magnify" v-model="itinerary_name"
                        @keyup.enter="searchBookings()"></v-text-field>
                </v-col>
                <v-col class="px-3">
                    <v-text-field label="Itinerary Number" prepend-icon="mdi-magnify" v-model="itinerary_number"
                        @keyup.enter="searchBookings()"></v-text-field>
                </v-col> -->
                <v-col class="px-3">
                    <v-select :items="get_booking_statuses" v-model="booking_status_id" item-text="status"
                        item-value="id" label="Booking Status"></v-select>
                </v-col>
                <v-col class="px-3">
                    <v-select :items="get_booking_payment_statuses" v-model="booking_payment_status_id"
                        item-text="status" item-value="id" label="Payment Status"></v-select>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="1" style="display:flex;align-items:center;justify-content:end;">
                    Check In Date
                </v-col>
                <v-col class="px-3">
                    <v-menu v-model="start_from_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="start_from_date" label="From" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="start_from_date"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col class="px-3">
                    <v-menu v-model="start_to_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="start_to_date" label="To" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="start_to_date"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="1" style="display:flex;align-items:center;justify-content:end;">
                    Check Out Date
                </v-col>
                <v-col class="px-3">
                    <v-menu v-model="end_from_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="end_from_date" label="From" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="end_from_date"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col class="px-3">
                    <v-menu v-model="end_to_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="end_to_date" label="To" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="end_to_date"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="2">
                    <div style="display:flex;align-items:center;justify-content:center;">
                        <v-btn @click="searchBookings()" rounded color="primary">Search</v-btn>
                        <v-btn @click="resetSearch()" rounded text class="ml-2">Reset</v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="pt-5">
                    <v-divider></v-divider>
                    <v-skeleton-loader type="table" v-if="!loaded"></v-skeleton-loader>
                    <v-data-table v-if="loaded" :headers="booking_table_header" :items="bookings" item-key="id"
                        :sort-by.sync="sort_by" :sort-desc.sync="sort_desc">
                        <template v-slot:item="{ item }">
                            <tr
                                :set="days = (item.day.itinerary.tour_type_id == 2 && item.option.product.supplier.service_type_id == 3) // IF ES AND TRANSPO, ADD DAY
                                    ? $date(item.check_out_date).diff($date(item.check_in_date), 'd') + 1 : $date(item.check_out_date).diff($date(item.check_in_date), 'd')">
                                <td>
                                    <input @click="selectBooking(item)"
                                        :checked="selected_bookings.findIndex((booking) => booking.id == item.id) > -1 ? true : false"
                                        type="checkbox" style="cursor:pointer;width:20px;height:20px;" />
                                </td>
                                <td>
                                    <b>{{ item.id }}</b>
                                </td>
                                <td>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-wrap">
                                                <b>{{ item.option.product.supplier.supplier_name }}</b>
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="item.option.product">
                                                <v-icon color="black" small>
                                                    {{
                                                        item.option.product.supplier.service_type_id == 1
                                                            ? 'mdi-bed' : item.option.product.supplier.service_type_id == 2
                                                                ? 'mdi-walk' : item.option.product.supplier.service_type_id == 3
                                                                    ? 'mdi-car-back' : 'mdi-plus-circle'
                                                    }}
                                                </v-icon>
                                                <small class="ml-2 text-wrap">{{ item.option.product.name }}</small>
                                                <br>
                                                <small class="ml-6 text-wrap">
                                                    <b>x{{ item.count }}</b>
                                                    {{ item.option.name }}
                                                </small>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </td>
                                <td>
                                    <div style="display:flex;align-items:center;">
                                        <v-btn @click="openItinerary(item.day.itinerary)" text
                                            style="font-weight:700;font-size:16px;color:#389A74;text-transform:initial;padding:0px;letter-spacing:initial;">
                                            {{ item.day.itinerary.itinerary_name }}
                                        </v-btn>
                                        <v-btn icon small>
                                            <a :href="app_url + 'itinerary/update/' + item.day.itinerary.id"
                                                target="_blank">
                                                <v-icon style="color:#389A74;" size="20"
                                                    class="ml-2">mdi-open-in-new</v-icon>
                                            </a>
                                        </v-btn>
                                    </div>
                                </td>
                                <td>
                                    <small>
                                        {{ $date(item.check_in_date).format('MMM DD, YYYY') }}
                                        <br>
                                        <label v-if="item.check_out_date != item.check_in_date">
                                            {{ $date(item.check_out_date).format('MMM DD, YYYY') }}
                                        </label>
                                    </small>
                                    <small v-if="item.option.product.supplier.service_type_id == 1">
                                        {{ (days > 0 ? days : 1) }} night<label v-if="days > 1">s</label>
                                    </small>
                                    <small v-else>
                                        {{ (days > 0 ? days : 1) }} day<label v-if="days > 1">s</label>
                                    </small>
                                </td>
                                <td>
                                    <v-list-item v-if="item.rate_day && item.day">
                                        <v-list-item-content
                                            :set="rate = item.rate_day.net * (days > 0 ? days : 1) * item.count"
                                            :set2="converted_rate = item.rate_day.net * item.conversion * (days > 0 ? days : 1) * item.count">
                                            <v-list-item-title>
                                                <small>
                                                    <b>{{ item.day.itinerary.currency.code }} </b>
                                                    <label
                                                        v-if="item.option.product.supplier.currency_id == item.day.itinerary.currency_id">
                                                        <b>{{ rate.toFixed(2) }}</b>
                                                    </label>
                                                    <label v-else>
                                                        <b>{{ converted_rate.toFixed(2) }}</b>
                                                    </label>
                                                </small>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <small>
                                                    {{ item.day.itinerary.currency.code }}
                                                    <label
                                                        v-if="item.option.product.supplier.currency_id == item.day.itinerary.currency_id">
                                                        {{ item.rate_day.net }}
                                                    </label>
                                                    <label v-else>
                                                        {{ (item.rate_day.net * item.conversion).toFixed(2) }}
                                                    </label>
                                                    <br>*
                                                    <label v-if="item.option.product.supplier.service_type_id == 1">
                                                        {{ (days > 0 ? days : 1) }} night<label
                                                            v-if="days > 1">s</label>
                                                    </label>
                                                    <label v-else>
                                                        {{ (days > 0 ? days : 1) }} day<label v-if="days > 1">s</label>
                                                    </label>
                                                    <br>* {{ item.count }} count
                                                    <br>
                                                    <label style="color:grey;"
                                                        v-if="item.option.product.supplier.currency_id != item.day.itinerary.currency_id">
                                                        ({{ item.option.product.supplier.currency.code }}
                                                        {{ item.rate_day.net }})
                                                    </label>
                                                </small>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-else>
                                        <small>No rate found for this period</small>
                                    </v-list-item>
                                </td>
                                <td>
                                    <v-list-item v-if="item.rate_day && item.day">
                                        <v-list-item-content
                                            :set="rate = item.rate_day.gross * (days > 0 ? days : 1) * item.count"
                                            :set2="converted_rate = item.rate_day.gross * item.conversion * (days > 0 ? days : 1) * item.count">
                                            <v-list-item-title>
                                                <small>
                                                    <b>{{ item.day.itinerary.currency.code }} </b>
                                                    <label
                                                        v-if="item.option.product.supplier.currency_id == item.day.itinerary.currency_id">
                                                        <b>{{ rate.toFixed(2) }}</b>
                                                    </label>
                                                    <label v-else>
                                                        <b>{{ converted_rate.toFixed(2) }}</b>
                                                    </label>
                                                </small>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <small>
                                                    {{ item.day.itinerary.currency.code }}
                                                    <label
                                                        v-if="item.option.product.supplier.currency_id == item.day.itinerary.currency_id">
                                                        {{ item.rate_day.gross }}
                                                    </label>
                                                    <label v-else>
                                                        {{ (item.rate_day.gross * item.conversion).toFixed(2) }}
                                                    </label>
                                                    <br>*
                                                    <label v-if="item.option.product.supplier.service_type_id == 1">
                                                        {{ (days > 0 ? days : 1) }} night<label
                                                            v-if="days > 1">s</label>
                                                    </label>
                                                    <label v-else>
                                                        {{ (days > 0 ? days : 1) }} day<label v-if="days > 1">s</label>
                                                    </label>
                                                    <br>* {{ item.count }} count
                                                    <br>
                                                    <label style="color:grey;"
                                                        v-if="item.option.product.supplier.currency_id != item.day.itinerary.currency_id">
                                                        ({{ item.option.product.supplier.currency.code }}
                                                        {{ item.rate_day.gross }})
                                                    </label>
                                                </small>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-else>
                                        <small>No rate found for this period</small>
                                    </v-list-item>
                                </td>
                                <td>
                                    <v-select :items="get_booking_statuses" v-model="item.status_id"
                                        @change="updateBooking(item)" item-text="status" item-value="id" chips>
                                        <template #selection="{ item }">
                                            <v-chip :color="getColor(item.id)" style="cursor:pointer;">
                                                {{ item.status }}
                                            </v-chip>
                                        </template>
                                    </v-select>
                                </td>
                                <td>
                                    <v-select :items="get_booking_payment_statuses" v-model="item.payment_status_id"
                                        @change="updateBooking(item)" item-text="status" item-value="id" chips>
                                        <template #selection="{ item }">
                                            <v-chip :color="getColorPricing(item.id)" style="cursor:pointer;">
                                                {{ item.status }}
                                            </v-chip>
                                        </template>
                                    </v-select>
                                </td>
                                <td>
                                    <v-btn @click="openUpdateBookingDialog(item)" text>
                                        {{ item.booking_reference_number }}
                                        <v-icon class="ml-5">mdi-pen</v-icon>
                                    </v-btn>
                                </td>
                                <td>
                                    <v-btn @click="openUpdateNotesDialog(item)" text>
                                        <v-icon>mdi-notebook</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="update_supplier_reference_dialog" persistent max-width="600">
            <v-card v-if="selected_booking">
                <v-card-title>
                    <label>Update supplier reference ID</label>
                </v-card-title>
                <v-divider />
                <v-card-text class="mt-5">
                    <v-text-field v-model="selected_booking.booking_reference_number" label="Supplier Reference ID"
                        dense outlined>
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn outlined class="pl-3 pr-3" rounded @click="closeUpdateBookingDialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Close
                    </v-btn>
                    <v-btn color="primary" class="pl-8 pr-8" rounded @click="updateBooking(selected_booking)">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="update_notes_dialog" persistent max-width="600">
            <v-card v-if="selected_booking">
                <v-card-title>
                    <label>Update notes</label>
                </v-card-title>
                <v-divider />
                <v-card-text class="mt-5">
                    <wysiwyg v-model="selected_booking.notes" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn outlined class="pl-3 pr-3" rounded @click="closeUpdateNotesDialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Close
                    </v-btn>
                    <v-btn color="primary" class="pl-8 pr-8" rounded @click="updateBooking(selected_booking)">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <SupplierEmailDialog v-if="supplier_email_dialog" :supplier_email_type="supplier_email_type"
            :supplier_email_dialog="supplier_email_dialog" :grouped_bookings="grouped_bookings"
            @close="closeSupplierEmailDialog" @sendSupplierEmails="sendSupplierEmails" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SupplierEmailDialog from './components/booking/dialogs/SupplierEmailDialog.vue'
export default {
    name: 'Bookings',
    components: {
        SupplierEmailDialog
    },
    props: [],
    data: () => ({
        bookings: [],
        app_url: null,
        booking_table_header: [
            { text: '', width: '1px' },
            { text: 'Booking ID', value: 'id', sortable: true, width: '1px' },
            { text: 'Item', value: 'option.product.supplier.supplier_name', sortable: true, width: '350px' },
            { text: 'Itinerary Name', value: 'day.itinerary.itinerary_name', sortable: true, width: '250px' },
            { text: 'Days/Nts', value: 'check_in_date', sortable: true, width: '100px' },
            { text: 'Net', value: 'net', sortable: false, width: '50px' },
            { text: 'Gross', value: 'gross', sortable: false, width: '50px' },
            { text: 'Booking Status', value: 'status_id', sortable: true, width: '150px' },
            { text: 'Payment Status', value: 'payment_status_id', sortable: true, width: '150px' },
            { text: 'Supplier Reference', value: 'booking_reference_number' },
            { text: 'Notes', value: 'notes' },
        ],
        sort_by: 'check_in_date',
        sort_desc: false,
        selected_booking: null,
        selected_bookings: [],
        grouped_bookings: [],
        supplier_details_dialog: false,
        update_supplier_reference_dialog: false,
        update_notes_dialog: false,
        supplier_email_dialog: false,
        cancellation_request_dialog: false,
        selected_supplier: null,
        supplier_email_type: null,
        select_all: false,

        loaded: true,
        supplier_name: null,
        booking_reference_number: null,
        booking_id: null,
        booking_status_id: null,
        booking_payment_status_id: null,
        start_from_menu: false,
        start_to_menu: false,
        start_from_date: null,
        start_to_date: null,
        end_from_menu: false,
        end_to_menu: false,
        end_from_date: null,
        end_to_date: null,
    }),
    async mounted() {
        this.app_url = process.env.VUE_APP_URL
        this.start_from_date = this.$date().format('YYYY-MM-DD')
        this.start_to_date = this.$date().format('YYYY-MM-DD')
        // this.searchBookings()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_user: 'auth/get_user',
            get_statuses: 'itineraryV2/get_statuses',
            get_booking_statuses: 'itineraryV2/get_booking_statuses',
            get_booking_payment_statuses: 'itineraryV2/get_booking_payment_statuses',
        }),
    },
    methods: {
        async searchBookings() {
            this.selected_bookings = []
            this.loaded = false
            this.start_from_menu = false
            this.start_to_menu = false
            this.end_from_menu = false
            this.end_to_menu = false
            let payload = {
                supplier_name: (this.supplier_name != null && this.supplier_name != '') ? this.supplier_name : null,
                booking_reference_number: (this.booking_reference_number != null && this.booking_reference_number != '') ? this.booking_reference_number : null,
                booking_id: (this.booking_id != null && this.booking_id != '') ? this.booking_id : null,
                booking_status_id: (this.booking_status_id != null && this.booking_status_id != '') ? this.booking_status_id : null,
                booking_payment_status_id: (this.booking_payment_status_id != null && this.booking_payment_status_id != '') ? this.booking_payment_status_id : null,
                start_from_date: (this.start_from_date != null && this.start_from_date != 'None') ? this.start_from_date : null,
                start_to_date: (this.start_to_date != null && this.start_to_date != 'None') ? this.start_to_date : null,
                end_from_date: (this.end_from_date != null && this.end_from_date != 'None') ? this.end_from_date : null,
                end_to_date: (this.end_to_date != null && this.end_to_date != 'None') ? this.end_to_date : null,
            }
            this.$axios.get(`v2/itineraries/get_bookings_by_filter/${this.get_selected_company.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.bookings = data.data
                        this.bookings.forEach(booking => {
                            booking.conversion = booking.day.itinerary.currency.code == 'EUR' ? data.gbp_to_eur : booking.day.itinerary.currency.code == 'GBP' ? data.eur_to_gbp : 1
                        })
                        this.loaded = true
                    }
                })
        },
        resetSearch() {
            this.loaded = false
            this.booking_reference_number = null
            this.booking_id = null
            this.supplier_name = null
            this.booking_status_id = null
            this.booking_payment_status_id = null
            this.start_from_date = this.$date().format('YYYY-MM-DD')
            this.start_to_date = this.$date().format('YYYY-MM-DD')
            this.end_from_date = null
            this.end_to_date = null
            this.searchBookings()
        },
        openItinerary(item) {
            this.$router.push(`/itinerary/update/${item.id}`)
        },
        selectBooking(booking) {
            let index = this.selected_bookings.findIndex((item) => item.id == booking.id)
            if (index == -1) {
                this.selected_bookings.push(booking)
            }
            else {
                this.selected_bookings.splice(index, 1)
            }
            if (this.selected_bookings.length == this.bookings.length) {
                this.select_all = true
            }
            else {
                this.select_all = false
            }
        },
        selectAll() {
            if (this.selected_bookings.length == this.bookings.length) {
                this.selected_bookings = []
            }
            else {
                this.selected_bookings = []
                this.bookings.forEach(booking => {
                    this.selected_bookings.push(booking)
                })
            }
        },
        openUpdateBookingDialog(data) {
            this.update_supplier_reference_dialog = true
            this.selected_booking = data
        },
        closeUpdateBookingDialog() {
            this.update_supplier_reference_dialog = false
            this.selected_booking = null
        },
        openUpdateNotesDialog(data) {
            this.update_notes_dialog = true
            this.selected_booking = data
        },
        closeUpdateNotesDialog() {
            this.update_notes_dialog = false
            this.selected_booking = null
        },
        async updateBooking(item) {
            let payload = {
                ...item,
            }
            await this.$axios.post(`v2/itineraries/update_booking/0/${item.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateBookingDialog()
                        this.closeUpdateNotesDialog()
                    }
                })
        },
        openSupplierEmailDialog(type) {
            this.supplier_email_type = type
            const supplierMap = {}
            let indexCounter = 0
            this.grouped_bookings = []
            this.selected_bookings.forEach((booking) => {
                const supplierId = booking.option.product.supplier.id
                if (supplierMap[supplierId] === undefined) {
                    supplierMap[supplierId] = indexCounter
                    this.grouped_bookings[indexCounter] = []
                    indexCounter++
                }
                const groupIndex = supplierMap[supplierId]
                this.grouped_bookings[groupIndex].push(booking)
            })

            this.grouped_bookings.forEach(supplier_bookings => {
                let total = parseFloat(0)
                let booking_ids = ''
                supplier_bookings[0].email = 'Hi ' + supplier_bookings[0].option.product.supplier.supplier_name + ',<br><br>'
                supplier_bookings[0].email += 'We have sent you a payment for the below group through online banking today. '
                supplier_bookings[0].email += 'If your bank is the Bank of Ireland, you will receive the payment right away. '
                supplier_bookings[0].email += 'If you are with a bank other than the Bank of Ireland, it will take 2 to 3 working days for the payment to reach your account. '
                supplier_bookings[0].email += 'The account name should appear as DG Ventures Ltd / ' + supplier_bookings[0].day.itinerary.company.company_name + '.<br><br>'

                supplier_bookings[0].email += '<table style="width:100%;"><tr>'
                supplier_bookings[0].email += '<th style="text-align:left;padding:10px;">Arrival Date</th>'
                supplier_bookings[0].email += '<th style="text-align:left;padding:10px;">Booking Name</th>'
                supplier_bookings[0].email += '<th style="text-align:left;padding:10px;">Amount Paid</th></tr>'
                supplier_bookings.forEach((booking) => {
                    booking_ids += booking.id + ','
                    let days = (booking.day.itinerary.tour_type_id == 2 && booking.option.product.supplier.service_type_id == 3)
                        ? this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') + 1
                        : this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd')
                    let amount = (booking.rate_day ? booking.rate_day.net : 0) * (days > 0 ? days : 1) * booking.count
                    total += parseFloat(amount)

                    supplier_bookings[0].email += '<tr><td style="padding:10px;">' + booking.check_in_date + '</td>'
                    supplier_bookings[0].email += '<td style="padding:10px;">' + booking.day.itinerary.itinerary_name + ' - ' + booking.day.itinerary.itinerary_number + ' - ' + booking.id + '</td>'
                    supplier_bookings[0].email += '<td style="padding:10px;">' + booking.option.product.supplier.currency.symbol + parseFloat(amount).toFixed(2) + '</td></tr>'
                })
                supplier_bookings[0].email += '<tr><td></td><td></td><td></td></tr>'
                supplier_bookings[0].email += '<tr><td></td><td style="padding:10px;"><b>Total Amount Paid</b></td><td style="padding:10px;"><b>' + supplier_bookings[0].option.product.supplier.currency.symbol + parseFloat(total).toFixed(2) + '</b></td></tr></table><br>'

                supplier_bookings[0].email += '<div style="text-align:center;">'
                let confirm_link = this.app_url + 'confirm_payment/' + booking_ids
                let confirm_link_style = 'background-color:#1976D2;color:white!important;border-radius:8px;padding: 20px 40px;font-weight:600;font-size:16px;'
                supplier_bookings[0].email += '<br><br><br><a href="' + confirm_link + '" style="' + confirm_link_style + '">CONFIRM</a><br><br><br>'
                supplier_bookings[0].email += '</div>'

                supplier_bookings[0].email += '<label>Kindly please confirm the receipt of the same. Should you have any questions please do not hesitate to contact me.</label><br><br>'
                supplier_bookings[0].email += '<label>Thank you,</label><br><br>'
            })
            this.supplier_email_dialog = true
        },
        closeSupplierEmailDialog() {
            this.supplier_email_dialog = false
        },
        async sendSupplierEmails(bookings_to_send) {
            let payload = {
                company: this.get_selected_company,
                grouped_bookings: bookings_to_send,
                supplier_email_type: this.supplier_email_type,
            }
            await this.$axios.post('v2/itineraries/send_supplier_emails', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeSupplierEmailDialog()
                        this.searchBookings()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openCancellationRequestDialog() {
            this.cancellation_request_dialog = true
        },
        closeCancellationRequestDialog() {
            this.cancellation_request_dialog = false
        },
        async sendCancellationRequest() {

        },
        openSupplierDetailsDialog(item) {
            this.supplier_details_dialog = true
            this.selected_booking = item
        },
        closeSupplierDetailsDialog() {
            this.supplier_details_dialog = false
            this.selected_booking = null
        },
        getColor(status_id) {
            if (status_id != null) {
                switch (status_id) {
                    case 1:
                        return 'primary'
                    case 2:
                        return 'warning'
                    case 3:
                        return 'success'
                    case 4:
                        return 'error'
                    case 5:
                        return 'error'
                    case 7:
                        return 'error'
                    default:
                        return 'primary'
                }
            } else {
                return 'primary'
            }
        },
        getColorPricing(status_id) {
            if (status_id != null) {
                switch (status_id) {
                    case 1:
                        return 'warning'
                    case 2:
                        return 'primary'
                    case 3:
                        return 'success'
                    default:
                        return 'primary'
                }
            } else {
                return 'primary'
            }
        },
    },
};
</script>

<style scoped>
.header {
    max-width: 100%;
    background-color: #79a2bd;
    height: 50px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
    text-align: center;
    position: relative;
    z-index: 1;
    line-height: 50px;
}

.header h3 {
    color: white;
}

.card {
    max-width: 100%;
    background-color: white;
    margin: 25px 15px;
    padding: 25px 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}

.card .card-header {
    font-size: 24px;
    color: #343642;
    margin-bottom: 15px;
}

.card .card-header-items {
    text-align: right;
}

.card .card-header-items .header-items {
    margin: 0px 10px;
}

.filtertabs-wrapper {
    max-width: 100%;
    margin-top: 18px;
}

.data-chip {
    background-color: rgb(56, 154, 116) !important;
    font-weight: bolder;
    font-size: 12px;
    color: white;
}

.text-center {
    text-align: center;
}

@media only screen and (max-width: 959px) {
    .card .card-header {
        text-align: center;
    }

    .card .card-header-items .header-items {
        margin: 5px 5px 10px 5px;
    }
}
</style>

<template>
    <v-dialog :value="update_payment_dialog" persistent max-width="500">
        <v-card v-if="selected_payment">
            <v-card-title class="d-flex">
                Update Payment
                <v-spacer />
                <v-btn @click="close()" variant="text" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row class="justify-center">
                    <v-col cols="6">
                        <v-select v-model="updated_payment.payment_type_id" :items="payment_types" item-text="name"
                            item-value="id" label="Payment Type *" />
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="updated_payment.currency_id" :items="get_currencies" item-text="code"
                            item-value="id" label="Currency" disabled />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="updated_payment.amount" @change="updateTotal" type="number" min="0"
                            label="Itinerary Amount *" />
                        <v-text-field v-model="updated_payment.surcharge" @change="updateTotal" type="number" min="0"
                            label="Surcharge" />
                        <v-text-field v-model="updated_payment.tip_amount" @change="updateTotal" type="number" min="0"
                            label="Tip" />
                        <v-text-field v-model="updated_payment.total_amount" type="number" disabled
                            label="Total Amount" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_payment.vendor_tx_code" label="Vendor Transaction Code" />
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="updated_payment.method"
                            :items="['Bank transfer', 'Credit card', 'Cash', 'Cheque', 'Voucher', 'Stripe', 'Sagepay', 'Authipay', 'Other']"
                            label="Payment Method" />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="updated_payment.notes" label="Notes" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Cancel
                </v-btn>
                <v-btn @click="updatePayment()" rounded color="primary" class="px-5">
                    Update
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: [
        'update_payment_dialog', 'selected_payment', 'itinerary', 'payment_types',
    ],
    data() {
        return {
            updated_payment: { ...this.selected_payment },
        }
    },
    computed: {
        ...mapGetters({
            get_currencies: 'itineraryV2/get_currencies',
        }),
    },
    methods: {
        close() {
            this.$emit('close')
        },
        updatePayment() {
            this.$emit('updatePayment', this.updated_payment)
        },
        updateTotal() {
            this.updated_payment.total_amount = parseFloat(this.updated_payment.amount) + parseFloat(this.updated_payment.surcharge) + parseFloat(this.updated_payment.tip_amount)
        },
    },
}
</script>
<template>
    <v-container fluid style="background-color: #f4f4f5 !important">
        <v-row class="mt-5" justify="center" align="center">
            <v-card elevation="2" style="width:1400px;">
                <v-card-title class="text-small">
                    <small><strong>Who Used</strong></small>
                    <v-spacer></v-spacer>
                    <download-csv :data="booking_data"
                        :name="start_from_date + ' to ' + start_to_date + ' Who Used Report for ' + supplier.supplier_name + '.csv'"
                        v-if="!loading">
                        <v-btn rounded color="primary" class="pt-4 pb-4 ml-2" style="text-transform: unset !important;">
                            <v-icon class="mr-2">
                                mdi-download-circle-outline
                            </v-icon>
                            Export
                        </v-btn>
                    </download-csv>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-select v-model="itinerary_status_id" label="Filter by Itinerary Status"
                                :items="[{ name: 'None', id: null }, ...get_statuses]" item-text="name"
                                item-value="id" @change="getBookings()"></v-select>
                        </v-col>
                        <v-col>
                            <v-select v-model="booking_status_id" label="Filter by Booking Status"
                                :items="[{ status: 'None', id: null }, ...get_booking_statuses]" item-text="status"
                                item-value="id" @change="getBookings()"></v-select>
                        </v-col>
                        <v-col>
                            <v-menu v-model="start_from_menu" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="start_from_date" label="From" readonly v-bind="attrs"
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="start_from_date" @input="getBookings()"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu v-model="start_to_menu" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="start_to_date" label="To" readonly v-bind="attrs"
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="start_to_date" @input="getBookings()"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-btn @click="getBookings()" color="primary" rounded>Search</v-btn>
                        </v-col>
                    </v-row>
                    <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
                    <v-data-table v-if="!loading" :items="itineraries" :headers="headers" disable-pagination
                        hide-default-footer>
                        <template v-slot:item="{ item }">
                            <tr v-if="item.days[0]">
                                <td>{{ $date(item.start_date).format('D/MM/YYYY') }}</td>
                                <td>{{
                                    $date(item.days[0].bookings[0].check_out_date).diff($date(item.days[0].bookings[0].check_in_date),
                                    'DAY') }}</td>
                                <td>{{ item.days[0].bookings[0].count }}</td>
                                <td>{{ $date(item.end_date).diff($date(item.start_date), 'DAY') }}</td>
                                <td>
                                    {{ item.contributors.length > 0 && item.contributors[0].user
                                    ? item.contributors[0].user.name : 'N/A' }}
                                </td>
                                <td><a :href="app_url + 'itinerary/update/' + item.id" target="_blank">
                                    {{ item.itinerary_name }}
                                    </a></td>
                                <td>{{ item.itinerary_number }}</td>
                                <td>
                                    {{ (item.days[0].bookings[0].option && item.days[0].bookings[0].option.product
                                    ? item.days[0].bookings[0].option.product.name + ', ' : '') +
                                    (item.days[0].bookings[0].option ? item.days[0].bookings[0].option.name : '')
                                    }}
                                </td>
                                <!-- <td>{{ item.currency + ' ' + item.net }}</td>
                                <td>{{ item.currency + ' ' + item.gross }}</td> -->
                                <td>{{ item.status.name }}</td>
                                <td>{{ (item.days[0].bookings[0] &&
                                    item.days[0].bookings[0].booking_status
                                    ? item.days[0].bookings[0].booking_status.status : 'Not Yet Requested') }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [ 'supplier' ],
    data: () => ({
        loading: false,
        headers: [
            { text: 'Arrive Date' },
            { text: 'Nights' },
            { text: 'Rooms / Qty' },
            { text: 'Total Nights' },
            { text: 'Agent' },
            { text: 'Itinerary Name' },
            { text: 'Itinerary Number' },
            { text: 'Service Option' },
            // { text: 'Net' },
            // { text: 'Gross' },
            { text: 'Itinerary Status' },
            { text: 'Booking Status' },
        ],
        itineraries: [],
        start_from_menu: false,
        start_to_menu: false,
        start_from_date: null,
        start_to_date: null,
        booking_data: [],
        app_url: null,
        itinerary_status_id: null,
        booking_status_id: null,
    }),
    async mounted() {
        this.app_url = process.env.VUE_APP_URL
        this.start_from_date = this.$date().startOf('M').format('YYYY-MM-DD')
        this.start_to_date = this.$date().endOf('M').format('YYYY-MM-DD')
        // this.getBookings()
    },
    created() { },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_statuses: 'itineraryV2/get_statuses',
            get_booking_statuses: 'itineraryV2/get_booking_statuses',
        }),
    },
    methods: {
        async getBookings() {
            this.loading = true
            this.start_from_menu = false
            this.start_to_menu = false
            let payload = {
                company_id: this.get_selected_company.id,
                start_from_date: (this.start_from_date != null && this.start_from_date != 'None') ? this.start_from_date : null,
                start_to_date: (this.start_to_date != null && this.start_to_date != 'None') ? this.start_to_date : null,
                itinerary_status_id: (this.itinerary_status_id != null && this.itinerary_status_id != 'None') ? this.itinerary_status_id : null,
                booking_status_id: (this.booking_status_id != null && this.booking_status_id != 'None') ? this.booking_status_id : null,
            }
            console.log(payload)
            await this.$axios.get(`v2/itineraries/get_itineraries_by_supplier/${this.supplier.id}`, payload)
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.itineraries = data.data
                        this.loading = false
                        this.prepareBookingData()
                    }
                })
        },
        prepareBookingData() {
            this.booking_data = []
            this.itineraries.forEach(item => {
                if (item.days[0]) {
                    let new_data = {
                        ArriveDate: this.$date(item.start_date).format('D/MM/YYYY'),
                        Nights: this.$date(item.days[0].bookings[0].check_out_date).diff(this.$date(item.days[0].bookings[0].check_in_date), 'DAY'),
                        Rooms_Qty: item.days[0].bookings[0].count,
                        Total_Nights: this.$date(item.end_date).diff(this.$date(item.start_date), 'DAY'),
                        Agent: item.contributors.length > 0 && item.contributors[0].user ? item.contributors[0].user.name : 'N/A',
                        Itinerary_Name: item.itinerary_name,
                        Itinerary_Number: item.itinerary_number,
                        Service_Option: (item.days[0].bookings[0].option && item.days[0].bookings[0].option.product ? item.days[0].bookings[0].option.product.name + ', ' : '') + (item.days[0].bookings[0].option ? item.days[0].bookings[0].option.name : ''),
                        // Net: item.net + ' ' + item.currency,
                        // Gross: item.gross + ' ' + item.currency,
                        Itinerary_Status: item.status.name,
                        Booking_Status: (item.days[0].bookings[0] && item.days[0].bookings[0].booking_status ? item.days[0].bookings[0].booking_status.status : 'Not Yet Requested'),
                    }
                    this.booking_data.push(new_data)
                }
            })
            console.log(this.booking_data)
        },
    },
    watch: {},
};
</script>

<style scoped lang="scss"></style>
<template>
    <v-container fluid style="background-color: #f4f4f5 !important">
        <v-row class="mt-5" justify="center" align="center">
            <v-card width="820" elevation="2">
                <v-card-title class="text-small">
                    <small><strong>Products</strong></small>
                    <v-spacer />
                    <v-btn color="#588BAD" rounded small class="pl-10 pr-10" dark
                        style="text-transform: none !important; color: white" @click="openCreateProductDialog()">
                        Create Product
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <!-- {{get_supplier_amenities}} -->
                    <!-- {{ supplier }} -->
                    <!-- {{ get_supplier_product }} -->
                    <v-checkbox v-model="show_archived" label="Show Archived"></v-checkbox>
                    <v-list>
                        <v-list-item-group v-for="(product, index) in supplier.products" :key="index">
                            <v-list-item v-if="product.is_archived == 0 || show_archived" two-line style="
                  border-top: 1px solid gray;
                  border-bottom: 1px solid gray;
                " class="mt-2" @click="selectProduct(product)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <label style="font-size: 16px">{{ product.name }}</label>
                                        <label v-if="product.is_archived" style="font-size:14px;color:grey;">
                                            (archived)</label>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-menu right bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list v-if="product.is_archived == 0">
                                            <v-list-item>
                                                <v-list-item-title @click="duplicateProduct(product)"> Duplicate
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title @click="archiveProduct(product)"> Archive
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        <v-list v-else>
                                            <v-list-item>
                                                <v-list-item-title @click="restoreProduct(product)"> Restore
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-row>
        <v-dialog :value="create_product_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>Create New Product</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Name *" v-model="new_product.name" outlined dense />
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete v-model="new_product.supplier_product_type_id"
                                :items="get_supplier_product_type" item-value="id" item-text="name" label="Type *"
                                outlined dense chips />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="new_product.code" label="Code" outlined dense />
                        </v-col>
                        <v-col cols="12">
                            <label>Description</label>
                            <wysiwyg v-model="new_product.description" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="closeCreateProductDialog()" text>Cancel</v-btn>
                    <v-btn @click="createProduct()" color="primary">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {},
    props: ['supplier'],
    data: () => ({
        loading: true,
        amenities: null,
        archive_switch: false,
        show_archived: false,
        create_product_dialog: false,
        new_product: {
            name: null,
            code: null,
            supplier_product_type_id: null,
            description: null,
        },
    }),
    async mounted() {
        this.$store.dispatch('supplier/fetch_supplier_product_type')
    },
    created() { },
    computed: {
        ...mapGetters({
            get_supplier_product_type: 'supplier/get_supplier_product_type',
        }),
    },
    methods: {
        openCreateProductDialog() {
            this.create_product_dialog = true
        },
        closeCreateProductDialog() {
            this.create_product_dialog = false
            this.new_product = {
                name: null,
                code: null,
                supplier_product_type_id: null,
                description: null,
            }
        },
        async createProduct() {
            let payload = {
                name: this.new_product.name,
                code: this.new_product.code,
                supplier_product_type_id: this.new_product.supplier_product_type_id,
                description: this.new_product.description,
                supplier_id: this.supplier.id,
            }
            await this.$axios.post('suppliers/products/add_products', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateProductDialog()
                        this.$emit('getSupplier')
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        selectProduct(item) {
            this.$router.push({ name: 'View Product', params: { supplier_id: this.supplier.id, product_id: item.id } })
        },
        async duplicateProduct(item) {
            await this.$axios.post('/suppliers/products/duplicate_product', item)
                .then(({ data }) => {
                    if (data.response) {
                        this.$emit('getSupplier')
                    }
                })

        },
        async archiveProduct(item) {
            await this.$axios.patch(`/suppliers/products/archive_product/${item.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$emit('getSupplier')
                    }
                })
        },
        async restoreProduct(item) {
            await this.$axios.patch(`/suppliers/products/restore_product/${item.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$emit('getSupplier')
                    }
                })
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss"></style>

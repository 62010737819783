<template>
    <v-container fluid class="mt-6">
        <div v-for="(day, day_index) in days" :key="'day' + day_index">
            <v-row v-for="(booking, booking_index) in day.bookings" :key="'booking' + booking_index" no-gutters>
                <v-col cols="12">
                    <v-row v-if="booking_index == 0" no-gutters style="margin-top:-5px;" class="lora">
                        <v-col :cols="12">
                            <label :style="(isMobile() ? '' : 'font-size:20px;') + 'color:#977236;font-weight:600;'">
                                <v-icon :color="itinerary.company.tertiary_color" size="35"
                                    style="left:-16px;line-height:0px;">mdi-circle</v-icon>
                                <label :style="'color:' + itinerary.company.tertiary_color">
                                    Day {{ day_index + 1 }}
                                </label>
                            </label>
                            <label :style="(isMobile() ? 'margin-left:5px;' : 'font-size:18px;margin-left:20px;') + 'font-weight:600;'">
                                <label style="color:black;">
                                    {{ $date(day.date).format('MMMM DD (dddd)') }}
                                </label>
                            </label>
                            <label v-if="day_index == 0"
                                :style="(isMobile() ? 'margin-left:5px;' : 'font-size:18px;margin-left:20px;') + 'font-weight:600;'">
                                <label style="color:black;">
                                    ARRIVAL
                                </label>
                            </label>
                            <label v-if="day_index == days.length - 1"
                                :style="(isMobile() ? 'margin-left:5px;' : 'font-size:18px;margin-left:20px;') + 'font-weight:600;'">
                                <label style="color:black;">
                                    DEPARTURE
                                </label>
                            </label>
                        </v-col>
                    </v-row>
                    <v-row v-if="booking_index == 0 && !booking.option.product.supplier.supplier_name.toLowerCase().includes('your agent')" class="pt-3"
                        :style="day_index < days.length - 1 ? 'border-left: solid ' + itinerary.company.tertiary_color + ' 2px;' : ''"
                        no-gutters>
                        <v-col :cols="isMobile() ? 6 : 4" class="pl-10 lora"
                            :contenteditable="!shareable_link">
                            <b>Destination:</b>
                        </v-col>
                        <v-col :cols="isMobile() ? 6 : 8" class="inter" :contenteditable="!shareable_link">
                            {{ booking.option.product.supplier.town + ', ' + booking.option.product.supplier.county }}
                        </v-col>
                    </v-row>
                    <v-row :class="{
                        'py-1': day_index >= days.length - 1,
                        'pb-6': booking_index === day.bookings.length - 1
                    }" :style="day_index < days.length - 1 ? 'border-left: solid ' + itinerary.company.tertiary_color + ' 2px;' : ''"
                        no-gutters>
                        <v-col :cols="isMobile() ? 6 : 4" class="pl-10 lora"
                            :contenteditable="!shareable_link">
                            <b
                                v-if="booking.option.product.supplier.service_type_id == 1 && isFirstBookingOfType(day.bookings, booking_index, 1)">
                                Accommodation:
                            </b>
                            <b
                                v-if="booking.option.product.supplier.service_type_id == 2 && isFirstBookingOfType(day.bookings, booking_index, 2)">
                                Activities:
                            </b>
                            <b
                                v-if="booking.option.product.supplier.service_type_id == 3 && isFirstBookingOfType(day.bookings, booking_index, 3)">
                                Transportation:
                            </b>
                            <b
                                v-if="booking.option.product.supplier.service_type_id == 4 && isFirstBookingOfType(day.bookings, booking_index, 4)">
                                Other:
                            </b>
                        </v-col>
                        <v-col :cols="isMobile() ? 6 : 7" class="inter" :contenteditable="!shareable_link">
                            <label v-if="show_supplier_name">{{ booking.option.product.supplier.supplier_name }}</label>
                            <label
                                v-if="show_supplier_name && show_product_name || show_supplier_name && show_option_name">
                                - </label>
                            <label v-if="show_product_name">{{ booking.option.product.name }}</label>
                            <label v-if="show_product_name && show_option_name">
                                - </label>
                            <label v-if="show_option_name">x{{ booking.count }} {{ booking.option.name }}</label>
                        </v-col>
                        <v-col cols="1" v-if="!shareable_link" style="height:0px;">
                            <v-btn v-if="!printing" @click="hideBooking(day_index, booking_index)" icon small
                                color="primary">
                                <v-icon size="20">mdi-eye-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
export default {
    props: [
        'days', 'itinerary', 'show_supplier_name', 'show_product_name', 'show_option_name', 'shareable_link', 'printing'
    ],
    methods: {
        hideBooking(day_index, booking_index) {
            let payload = {
                day_index: day_index,
                booking_index: booking_index,
            }
            this.$emit('hideBooking', payload)
        },
        isFirstBookingOfType(bookings, current_index, supplier_type_id) {
            const first_activity_index = bookings.findIndex(
                booking => booking.option.product.supplier.service_type_id == supplier_type_id
            )
            return current_index === first_activity_index
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    },
}
</script>

<style scoped lang='scss'>
:deep(label),
:deep(b),
:deep(.row) {
    page-break-inside: avoid !important;
}

.kavivanar {
    font-family: 'Kavivanar', serif !important;
}

.playfair {
    font-family: 'Playfair', serif !important;
}

.poppins {
    font-family: 'Poppins', sans-serif !important;
}

.lora {
    font-family: 'Lora', serif !important;
}

.inter {
    font-family: 'Inter', sans-serif !important;
}
</style>
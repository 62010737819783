<template>
    <v-dialog :value="show_invoice_dialog" persistent max-width="50%">
        <v-card>
            <v-card-title>
                <label v-if="!edit_invoice">Create incoming payment</label>
                <label v-else>Update incoming payment</label>
                <v-spacer />
                <v-btn @click="closeInvoice()" icon>
                    <v-icon dark>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle class="my-3">
                <h2>Invoice details</h2>
                Update the information in this section according to the invoice you have raised for your client.
            </v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field v-model="invoice.payment_name" label="Payment name*"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="invoice.payment_type" label="Payment type*"
                            :items="['Pre quotation', 'Deposit', 'Instalment', 'Final', 'Credit', 'ORR', 'Service charge', 'Refund']"></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="invoice.amount_due" type="number" label="Amount due*"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="invoice.due_date" type="date" label="Due date*"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="invoice.method" label="Method"
                            :items="['Bank transfer', 'Credit card', 'Cash', 'Cheque', 'Voucher', 'Stripe', 'PayPal', 'Other']"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="invoice.invoice_number" type="number"
                            label="Invoice number"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="invoice.bill_to" label="Bill to"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="invoice.billing_address" label="Billing address"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="invoice.notes" label="Notes"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-subtitle class="my-3">
                <h2>Amount paid</h2>
                Update the information in this section according to the actual funds that you have received from
                your
                client.
            </v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="invoice.payer" label="Payer"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="invoice.amount_paid" label="Amount paid"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="date" v-model="invoice.date_paid" label="Date paid"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="invoice.payment_note" label="Add a payment note"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn outlined rounded class="pl-5 pr-5" @click="closeInvoice()">
                    cancel
                </v-btn>
                <v-btn v-if="!edit_invoice" color="primary" rounded class="pl-5 pr-5" @click="saveInvoice()">
                    Create
                </v-btn>
                <v-btn v-else color="primary" rounded class="pl-5 pr-5" @click="editInvoice()">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    components: {
    },
    props: [
        'show_invoice_dialog', 'edit_invoice', 'invoice',
    ],
    data() {
        return {
            updated_invoice: { ...this.invoice },
        }
    },
    async mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        saveInvoice() {
            this.$emit('saveInvoice', this.updated_invoice)
        },
        editInvoice() {
            this.$emit('editInvoice', this.updated_invoice)
        },
        closeInvoice() {
            this.$emit('closeInvoice')
        },
    },
}
</script>

<style scoped lang='scss'></style>
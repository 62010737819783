<template>
    <v-container fluid>
        <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
        <v-card v-else>
            <v-card-title>
                <strong>Company</strong>
                <v-spacer></v-spacer>
                <v-btn @click="openCreateCompanyDialog()" color="#525A68" class="pl-10 pr-10" dark rounded
                    :disabled="get_user.user_role_id === 1 ? false : true">
                    Add
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headers" :items="companies">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                {{ item.company_name }}
                            </td>
                            <td>
                                {{ $date(item.created_at).format('MMM DD, YYYY') }}
                            </td>
                            <td>
                                <v-btn @click="openUpdateCompanyDialog(item)" icon>
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item.action></template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-row justify="center">
            <v-dialog max-width="620" v-model="create_company_dialog" persistent>
                <v-card>
                    <v-card-title>
                        <small>Create Company</small>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeCreateCompanyDialog()" icon>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="new_company_name" dense label="Company Name *" outlined
                            prepend-inner-icon="mdi-tooltip-text-outline">
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="createCompany()" block color="#588BAD" dark small rounded>
                            Create
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <UpdateCompanyDialog v-if="selected_company" :update_company_dialog="update_company_dialog"
            :selected_company="selected_company" @close="closeUpdateCompanyDialog" @updateCompany="updateCompany" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import UpdateCompanyDialog from './dialogs/UpdateCompanyDialog.vue'

export default {
    components: {
        UpdateCompanyDialog,
    },
    props: [
    ],
    data: () => ({
        loading: false,
        companies: [],
        headers: [
            { text: 'Company Name' },
            { text: 'Created At' },
            { text: 'Actions' }
        ],
        create_company_dialog: false,
        new_company_name: null,
        update_company_dialog: false,
        selected_company: null,
    }),
    mounted() {
        this.getCompanies()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
        })
    },
    methods: {
        async getCompanies() {
            this.loading = true
            await this.$axios.get('v2/companies/get_companies')
                .then(({ data }) => {
                    if (data.response) {
                        this.companies = data.data
                        this.loading = false
                    }
                })
        },
        openCreateCompanyDialog() {
            this.create_company_dialog = true
        },
        closeCreateCompanyDialog() {
            this.create_company_dialog = false
            this.new_company_name = null
        },
        async createCompany() {
            let payload = {
                company_name: this.new_company_name,
                user_id: this.get_user.id,
            }
            await this.$axios.post('v2/companies/create_company', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateCompanyDialog()
                        this.getCompanies()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openUpdateCompanyDialog(item) {
            this.update_company_dialog = true
            this.selected_company = item
        },
        closeUpdateCompanyDialog() {
            this.update_company_dialog = false
            this.selected_company = null
        },
        async updateCompany(updated_company) {
            let formData = new FormData()
            formData.append('company_name', updated_company.company_name)
            formData.append('website', updated_company.website ?? '')
            formData.append('email', updated_company.email ?? '')
            formData.append('accounts_email', updated_company.accounts_email ?? '')
            formData.append('logo_file', updated_company.logo_file ?? '')
            formData.append('secondary_file', updated_company.secondary_file ?? '')
            formData.append('cover_title', updated_company.cover_title ?? '')
            formData.append('cover_file', updated_company.cover_file ?? '')
            formData.append('cover_description', updated_company.cover_description ?? '')
            formData.append('cover_details', updated_company.cover_details ?? '')
            formData.append('primary_color', updated_company.primary_color ? (this.selected_company.primary_color == null ? updated_company.primary_color.hexa : updated_company.primary_color) : '')
            formData.append('secondary_color', updated_company.secondary_color ? (this.selected_company.secondary_color == null ? updated_company.secondary_color.hexa : updated_company.secondary_color) : '')
            formData.append('tertiary_color', updated_company.tertiary_color ? (this.selected_company.tertiary_color == null ? updated_company.tertiary_color.hexa : updated_company.tertiary_color) : '')
            formData.append('login_enabled', updated_company.login_enabled ?? '')
            formData.append('payment_enabled', updated_company.payment_enabled ?? '')
            formData.append('payment_gateway', updated_company.payment_gateway ?? '')
            formData.append('payment_mode', updated_company.payment_mode ?? '')
            formData.append('test_key', updated_company.test_key ?? '')
            formData.append('live_key', updated_company.live_key ?? '')
            formData.append('deposit_enabled', updated_company.deposit_enabled ?? '')
            formData.append('partial_enabled', updated_company.partial_enabled ?? '')
            formData.append('surcharge_percent', updated_company.surcharge_percent ?? '')
            formData.append('tip_amounts', updated_company.tip_amounts ?? '')
            formData.append('payment_faqs', updated_company.payment_faqs ?? '')
            await this.$axios.post(`v2/companies/update_company/${updated_company.id}`, formData)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateCompanyDialog()
                        this.getCompanies()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>
import { render, staticRenderFns } from "./SupplierProductSetupComponent.vue?vue&type=template&id=a3b8130c&scoped=true"
import script from "./SupplierProductSetupComponent.vue?vue&type=script&lang=js"
export * from "./SupplierProductSetupComponent.vue?vue&type=script&lang=js"
import style0 from "./SupplierProductSetupComponent.vue?vue&type=style&index=0&id=a3b8130c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3b8130c",
  null
  
)

export default component.exports
<template>
    <v-container fluid style="background-color: #F4F4F5 !important;">
        <v-row class="mt-5" justify="center" align="center">
            <v-card width="820" elevation="2">
                <v-card-title class="text-small">
                    <small><strong>Description</strong></small>
                </v-card-title>
                <v-card-subtitle>
                    Tell your customers a little about where they are staying; the information you provide here will
                    appear in their itinerary
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <label>Description</label>
                            <wysiwyg v-model="supplier.description" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="#588BAD" dark class="pl-10 pr-10" small rounded @click="save_description"
                        style="text-transform: none !important; color: white;">
                        Save Description
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'supplier'
    ],
    data: () => ({
    }),
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        })
    },
    methods: {
        async save_description() {
            let payload = {
                supplier_id: this.supplier.id,
                company_id: this.get_selected_company.id,
                description: this.supplier.description
            }
            await this.$axios.patch('/suppliers/update_description', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success('Updated supplier successfully')
                    }
                })
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>
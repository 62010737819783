<template>
    <v-dialog :value="create_quick_booking_dialog" persistent max-width="770px">
        <v-card v-if="itinerary">
            <v-card-title class="text-h5">
                Add Quick Supplier & Booking
                <v-spacer />
                <v-icon style="cursor:pointer;" @click="close()">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-divider class="mb-5"></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field v-model="quick_supplier.supplier_name" label="Supplier Name *"
                            dense></v-text-field>
                        <v-text-field v-model="quick_supplier.email" label="Supplier Email" dense></v-text-field>
                        <v-select v-model="quick_supplier.service_type_id" label="Supplier Type *"
                            :items="get_supplier_types" item-value="id" item-text="name" dense></v-select>
                        <v-select v-model="quick_supplier.currency_id" :items="get_currencies" item-text="code"
                        item-value="id" label="Currency *"
                            dense></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="quick_supplier.supplier_product_name" label="Product Name *"
                            dense></v-text-field>
                        <v-text-field v-model="quick_supplier.supplier_product_option_name" label="Option Name *"
                            dense></v-text-field>
                        <v-select v-model="quick_supplier.product_type_id" label="Product Type *"
                            :items="get_product_types" item-value="id" item-text="name" dense></v-select>
                    </v-col>
                </v-row>
                <v-divider class="mt-5 mb-10"></v-divider>
                <v-row>
                    <v-col cols="6">
                        <v-menu v-model="check_in_menu" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="quick_supplier.check_in_date" label="check in date *"
                                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="quick_supplier.check_in_date" :min="itinerary.start_date"
                                :max="itinerary.end_date + 1" label="check in date *"
                                @input="check_in_menu = false"></v-date-picker>
                        </v-menu>
                        <v-text-field v-model="quick_supplier.check_in_time" type="time"
                            label="check in time (optional)">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-menu v-model="check_out_menu" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="quick_supplier.check_out_date" label="check out date *"
                                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="quick_supplier.check_out_date" :min="itinerary.start_date"
                                :max="itinerary.end_date + 1" label="check out date *"
                                @input="check_out_menu = false"></v-date-picker>
                        </v-menu>
                        <v-text-field v-model="quick_supplier.check_out_time" type="time"
                            label="check out time (optional)">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-text-field v-model="quick_supplier.count" type="number" label="count *"></v-text-field>
                <v-divider class="mt-5 mb-10"></v-divider>
                The below are optional if you want these fields to show on the itinerary.
                <v-row>
                    <v-col cols="6">
                        <v-file-input v-model="quick_supplier.supplier_image" label="Supplier Image"></v-file-input>
                    </v-col>
                    <v-col cols="6">
                        <v-file-input v-model="quick_supplier.product_image" label="Product Image"></v-file-input>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="quick_supplier.address" label="Address" dense></v-text-field>
                        <v-text-field v-model="quick_supplier.town" label="Town" dense></v-text-field>
                        <v-text-field v-model="quick_supplier.county" label="County" dense></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="quick_supplier.province" label="Province" dense></v-text-field>
                        <v-text-field v-model="quick_supplier.country" label="Country" dense></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <b>Description</b>
                        <wysiwyg v-model="quick_supplier.description" />
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="close()" rounded text class="px-5">
                    Cancel
                </v-btn>
                <v-btn @click="createQuickBooking()" color="primary" rounded class="px-5">Create</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'create_quick_booking_dialog', 'itinerary', 'selected_day'
    ],
    data() {
        return {
            check_in_menu: false,
            check_out_menu: false,
            quick_supplier: {
                supplier_name: '',
                service_type_id: 1,
                address: '',
                town: '',
                county: '',
                province: '',
                country: '',
                supplier_product_name: '',
                description: '',
                supplier_product_option_name: '',
                supplier_image: null,
                product_image: null,
                product_type_id: 1,
                check_in_date: '',
                check_in_time: '',
                check_out_date: '',
                check_out_time: '',
                count: 1,
            },
        }
    },
    async mounted() {
        this.quick_supplier.currency_id = this.itinerary.currency_id
        this.quick_supplier.check_in_date = this.itinerary.start_date
        this.quick_supplier.check_out_date = this.itinerary.start_date
    },
    async created() {

    },
    computed: {
        ...mapGetters({
            get_supplier_types: 'itineraryV2/get_supplier_types',
            get_product_types: 'itineraryV2/get_product_types',
            get_currencies: 'itineraryV2/get_currencies',
        })
    },
    methods: {
        close() {
            this.$emit('close')
            this.quick_supplier = {
                supplier_name: '',
                email: '',
                service_type_id: 1,
                address: '',
                town: '',
                county: '',
                province: '',
                country: '',
                supplier_product_name: '',
                description: '',
                supplier_product_option_name: '',
                supplier_image: null,
                product_image: null,
                product_type_id: 1,
                check_in_date: this.itinerary.start_date,
                check_in_time: '',
                check_out_date: this.itinerary.start_date,
                check_out_time: '',
                count: 1,
                currency_id: this.itinerary.currency_id,
            }
        },
        async createQuickBooking() {
            if (this.$date(this.quick_supplier.check_out_date).isBefore(this.$date(this.quick_supplier.check_in_date))) {
                this.$toast.error('End date must be greater than or equal to the start date')
                return
            }
            let formData = new FormData()
            formData.append('company_id', this.itinerary.company.id)
            formData.append('build_id', this.selected_day.id)
            formData.append('supplier_name', this.quick_supplier.supplier_name)
            formData.append('email', this.quick_supplier.email)
            formData.append('service_type_id', this.quick_supplier.service_type_id)
            formData.append('address', this.quick_supplier.address)
            formData.append('town', this.quick_supplier.town)
            formData.append('county', this.quick_supplier.county)
            formData.append('province', this.quick_supplier.province)
            formData.append('country', this.quick_supplier.country)
            formData.append('supplier_product_name', this.quick_supplier.supplier_product_name)
            formData.append('description', this.quick_supplier.description)
            formData.append('supplier_product_option_name', this.quick_supplier.supplier_product_option_name)
            formData.append('supplier_image', this.quick_supplier.supplier_image)
            formData.append('product_image', this.quick_supplier.product_image)
            formData.append('product_type_id', this.quick_supplier.product_type_id)
            formData.append('check_in_date', this.quick_supplier.check_in_date)
            formData.append('check_in_time', this.quick_supplier.check_in_time)
            formData.append('check_out_date', this.quick_supplier.check_out_date)
            formData.append('check_out_time', this.quick_supplier.check_out_time)
            formData.append('count', this.quick_supplier.count)
            formData.append('currency_id', this.quick_supplier.currency_id)
            await this.$axios.post('v2/suppliers/create_quick_supplier', formData)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.close()
                        this.$emit('refreshBookings')
                    } else {
                        this.$toast.error(data.message)
                    }
                })
        },
    },
}
</script>

<style scoped lang="scss"></style>
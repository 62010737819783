<template>
    <v-container fluid style="max-width:1400px;">
        <v-skeleton-loader type="table" v-if="loading_itinerary"></v-skeleton-loader>
        <v-row v-else>
            <v-col cols="6">
                <v-card>
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col cols="12" class="px-1">
                                <v-text-field v-model="itinerary.itinerary_name" label="Itinerary name *"
                                    style="font-size:18px;" dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="5"
                                    :nudge-bottom="61" transition="scale-transition" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="itinerary.start_date" label="Start date *" readonly
                                            v-bind="attrs" v-on="on" outlined />
                                    </template>
                                    <v-date-picker v-model="itinerary.start_date"
                                        @input="menu1 = false; date_changed = true;" />
                                </v-menu>
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="5"
                                    :nudge-bottom="61" transition="scale-transition" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="itinerary.end_date" label="End date *" readonly
                                            v-bind="attrs" v-on="on" outlined />
                                    </template>
                                    <v-date-picker v-model="itinerary.end_date"
                                        @input="menu2 = false; date_changed = true;" />
                                </v-menu>
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-text-field v-model="itinerary.itinerary_number" label="Itinerary number" outlined
                                    dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-text-field v-model="itinerary.internal_reference" label="Internal reference" outlined
                                    dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select :items="get_companies" v-model="itinerary.company_id" item-text="company_name"
                                    item-value="id" outlined dense label="Company" />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select v-model="itinerary.tour_type_id" :items="get_tour_types" item-value="id"
                                    item-text="name" label="Tour Type" outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select v-model="itinerary.currency_id" :items="get_currencies" item-text="code"
                                    item-value="id" label="Currency" outlined dense />
                            </v-col>
                            <v-col v-if="get_user.id == 98" cols="6" class="px-1">
                                <v-select v-model="itinerary.payment_currency_id" :items="get_currencies"
                                    item-text="code" item-value="id" label="Payment Gateway Currency" outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <div>
                                    <v-autocomplete v-model="itinerary.itinerary_status_id" :items="get_statuses"
                                        item-value="id" item-text="name" label="Status" outlined dense></v-autocomplete>
                                </div>
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <div>
                                    <v-autocomplete v-model="itinerary.itinerary_template_id" :items="get_templates"
                                        item-value="id" item-text="name" label="Template" outlined
                                        dense></v-autocomplete>
                                </div>
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-text-field v-model="itinerary.travellers_number" label="Total # of travellers"
                                    outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select v-model="itinerary.invoice_status_id" :items="get_invoice_statuses"
                                    item-value="id" item-text="name" label="Invoice Status" outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select v-model="itinerary.stage_id" :items="get_stages" item-value="id"
                                    item-text="name" label="Stage" outlined dense />
                            </v-col>
                            <!-- <v-col cols="6" class="px-1">
                                <v-text-field v-model="itinerary.partial_payment_percent"
                                    label="Partial Payment Limit %" outlined dense />
                            </v-col> -->
                            <v-col v-if="get_user.id == 98" cols="6" class="px-1">
                                <v-select v-model="itinerary.full_payment_enabled"
                                    :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                                    item-value="value" label="Enable Full Payment" outlined dense />
                            </v-col>
                            <v-col cols="12" class="px-1 mb-2">
                                <v-expansion-panels v-model="panel">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            Comments (to be shown on itinerary)
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <wysiwyg v-model="itinerary.comments" />
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="openDeleteItineraryDialog()" rounded color="red" class="px-5" text>
                            <v-icon>mdi-delete</v-icon>
                            Delete
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="duplicateItinerary()" rounded color="primary" text class="mx-8 px-5 py-2">
                            <b>Duplicate</b>
                        </v-btn>
                        <v-btn @click="updateItinerary()" rounded color="primary" class="px-8 py-6"
                            style="font-size:16px;">
                            <b>Update</b>
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <div class="d-flex mt-3" style="justify-content:space-between;">
                    <label>
                        <small>
                            <b>First Created At:</b>
                            {{ $date(itinerary.created_at).format('MMM DD, YYYY h:mm A') }}
                        </small>
                    </label>
                    <label>
                        <small>
                            <b>Last Updated At:</b>
                            {{ $date(itinerary.updated_at).format('MMM DD, YYYY h:mm A') }}
                        </small>
                    </label>
                </div>
            </v-col>
            <v-col cols="6">
                <v-card class="mb-5">
                    <v-card-title>
                        Assignees
                        <v-spacer></v-spacer>
                        <v-btn @click="openCreateContributorDialog()" color="primary" rounded>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row v-for="(contributor, index) in itinerary.contributors" :key="'contributor' + index"
                            class="my-3" style="border-top: solid 1px lightgrey;border-bottom:solid 1px lightgrey;">
                            <v-col cols="10">
                                <div v-if="contributor.user">
                                    <b>{{ contributor.user.name }}</b>
                                    <br>
                                    {{ contributor.user.email }}
                                </div>
                            </v-col>
                            <v-col cols="2" style="text-align:right;">
                                <v-btn @click="openDeleteContributorDialog(contributor)" color="red" icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mb-5">
                    <v-card-title>
                        Travellers
                        <v-spacer></v-spacer>
                        <v-btn @click="openCreateTravellerDialog()" color="primary" rounded>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-subtitle v-if="itinerary.company.login_enabled && get_user.id == 98">
                        Password: <b>{{ itinerary.password }}</b>
                        <v-btn @click="copyPassword()" icon class="ml-5">
                            <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row v-for="(traveller, index) in itinerary.travellers" :key="'traveller' + index"
                            class="my-3" style="border-top: solid 1px lightgrey;border-bottom:solid 1px lightgrey;">
                            <v-col cols="9">
                                <b>
                                    <label>{{ traveller.first_name + ' ' }}</label>
                                    <label>{{ traveller.last_name }}</label>
                                    <label v-if="traveller.is_organiser" class="access-label ml-5">Organiser</label>
                                </b>
                                <br>
                                <label>{{ traveller.email }}</label>
                            </v-col>
                            <v-col cols="3" style="text-align:right;">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="setOrganiser(traveller)" v-bind="attrs" v-on="on" color="green"
                                            icon>
                                            <v-icon dark>mdi-account-check-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Set Organiser</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="openUpdateTravellerDialog(traveller)" v-bind="attrs" v-on="on"
                                            color="primary" icon>
                                            <v-icon dark>mdi-pen</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Update Traveller</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="openDeleteTravellerDialog(traveller)" v-bind="attrs" v-on="on"
                                            color="red" icon>
                                            <v-icon dark>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete Traveller</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mb-5">
                    <v-card-title>
                        Notes
                        <v-spacer></v-spacer>
                        <div class="d-flex align-center">
                            <small class="mr-4">Oldest first</small>
                            <v-switch v-model="sort_by_newest" inset></v-switch>
                            <small class="mr-2">Newest first</small>
                        </div>
                        <v-btn @click="openCreateNoteDialog()" color="primary" rounded>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row v-for="(note, index) in sortedNotes" :key="'note' + index" class="my-3"
                            style="max-height:350px;overflow-y:auto;overflow-x:hidden;border-top: solid 1px lightgrey;border-bottom:solid 1px lightgrey;">
                            <v-col cols="10">
                                <div v-if="note.creator">
                                    <b style="color:black;">{{ note.creator.name }}</b> —
                                    {{ $date(note.created_at).format('MMM DD, YYYY h:m A') }}
                                </div>
                                <div v-html="note.note"></div>
                            </v-col>
                            <v-col cols="2" style="text-align:right;">
                                <v-btn @click="openUpdateNoteDialog(note)" color="primary" icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn @click="openDeleteNoteDialog(note)" color="red" icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="delete_itinerary_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>
                    Are you sure you want to delete this itinerary?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteItineraryDialog()" text>
                        Cancel
                    </v-btn>
                    <v-btn @click="deleteItinerary()" text color="red">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ContributorDialog :create_contributor_dialog="create_contributor_dialog"
            :delete_contributor_dialog="delete_contributor_dialog" :itinerary="itinerary" :users="users"
            :selected_contributor="selected_contributor" @closeCreateContributorDialog="closeCreateContributorDialog"
            @closeDeleteContributorDialog="closeDeleteContributorDialog" @refreshItinerary="refreshItinerary" />
        <TravellerDialog :create_traveller_dialog="create_traveller_dialog"
            :update_traveller_dialog="update_traveller_dialog" :delete_traveller_dialog="delete_traveller_dialog"
            :itinerary="itinerary" :selected_traveller="selected_traveller"
            @closeCreateTravellerDialog="closeCreateTravellerDialog"
            @closeUpdateTravellerDialog="closeUpdateTravellerDialog"
            @closeDeleteTravellerDialog="closeDeleteTravellerDialog" @refreshItinerary="refreshItinerary" />
        <NoteDialog :create_note_dialog="create_note_dialog" :update_note_dialog="update_note_dialog"
            :delete_note_dialog="delete_note_dialog" :itinerary="itinerary" :selected_note="selected_note"
            @closeCreateNoteDialog="closeCreateNoteDialog" @closeUpdateNoteDialog="closeUpdateNoteDialog"
            @closeDeleteNoteDialog="closeDeleteNoteDialog" @refreshItinerary="refreshItinerary" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ContributorDialog from './dialogs/ContributorDialog.vue'
import TravellerDialog from './dialogs/TravellerDialog.vue'
import NoteDialog from './dialogs/NoteDialog.vue'
export default {
    name: 'SetupComponent',
    components: {
        ContributorDialog, TravellerDialog, NoteDialog,
    },
    props: [
        'loading_itinerary', 'itinerary'
    ],
    data: () => ({
        users: [],
        date_changed: false,
        menu1: false,
        menu2: false,
        delete_itinerary_dialog: false,
        create_contributor_dialog: false,
        selected_contributor: null,
        delete_contributor_dialog: false,
        create_traveller_dialog: false,
        selected_traveller: null,
        update_traveller_dialog: false,
        delete_traveller_dialog: false,
        create_note_dialog: false,
        selected_note: null,
        update_note_dialog: false,
        delete_note_dialog: false,
        sort_by_newest: true,
        panel: null,
        app_url: null,
    }),
    async mounted() {
        this.app_url = process.env.VUE_APP_URL
        this.getUsers()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'auth/get_selected_company',
            get_companies: 'itineraryV2/get_companies',
            get_statuses: 'itineraryV2/get_statuses',
            get_templates: 'itineraryV2/get_templates',
            get_tour_types: 'itineraryV2/get_tour_types',
            get_invoice_statuses: 'itineraryV2/get_invoice_statuses',
            get_stages: 'itineraryV2/get_stages',
            get_currencies: 'itineraryV2/get_currencies',
        }),
        sortedNotes() {
            if (this.itinerary != null && this.itinerary.notes.length > 0) {
                if (this.sort_by_newest) {
                    let notes = [...this.itinerary.notes].sort(function (a, b) {
                        return b.id - a.id
                    })
                    return notes
                }
                else {
                    let notes = [...this.itinerary.notes].sort(function (a, b) {
                        return a.id - b.id
                    })
                    return notes
                }
            }
            return []
        },
    },
    methods: {
        async refreshItinerary(message = false, success = false) {
            this.$emit('refreshItinerary', success)
            if (message) {
                this.$toast.info('Refreshing...')
            }
        },
        async getUsers() {
            let payload = {
                company_id: this.get_selected_company.id,
            }
            await this.$axios.get('v2/users/get_users', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.users = data.data
                    }
                })
        },
        updateItinerary() {
            if (this.$date(this.itinerary.end_date).isBefore(this.$date(this.itinerary.start_date)) || this.$date(this.itinerary.end_date).isSame(this.$date(this.itinerary.start_date))) {
                this.$toast.error('End date must be greater than the start date')
                return
            }
            let payload = {
                ...this.itinerary,
            }
            this.$axios.post(`v2/itineraries/update_itinerary_setup/${this.itinerary.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.$emit('refreshBookings', true)
                    } else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async duplicateItinerary() {
            this.$toast('Please wait...')
            await this.$axios.post(`v2/itineraries/duplicate_itinerary/${this.itinerary.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        let link = this.app_url + 'itinerary/update/' + data.data.id
                        let myJSX = (
                            <div>
                                <div>Itinerary duplicated successfully, click below to view</div>
                                <a href={link} target="_blank" style="margin-top:10px;color:white;font-weight:800;">View Duplicate Itinerary</a>
                            </div>
                        )
                        this.$toast.success(myJSX, { timeout: 10000, closeOnClick: false, draggable: false })
                    }
                })
        },
        openDeleteItineraryDialog() {
            this.delete_itinerary_dialog = true
        },
        closeDeleteItineraryDialog() {
            this.delete_itinerary_dialog = false
        },
        deleteItinerary() {
            this.$axios.delete(`v2/itineraries/delete_itinerary/${this.itinerary.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteItineraryDialog()
                        this.$router.push({ name: 'Itineraries' })
                    }
                })
        },
        openCreateContributorDialog() {
            this.create_contributor_dialog = true
        },
        closeCreateContributorDialog() {
            this.create_contributor_dialog = false
        },
        openDeleteContributorDialog(item) {
            this.delete_contributor_dialog = true
            this.selected_contributor = item
        },
        closeDeleteContributorDialog() {
            this.delete_contributor_dialog = false
            this.selected_contributor = null
        },
        openCreateTravellerDialog() {
            this.create_traveller_dialog = true
        },
        closeCreateTravellerDialog() {
            this.create_traveller_dialog = false
        },
        openUpdateTravellerDialog(item) {
            this.update_traveller_dialog = true
            this.selected_traveller = item
        },
        closeUpdateTravellerDialog() {
            this.update_traveller_dialog = false
            this.selected_traveller = null
        },
        openDeleteTravellerDialog(item) {
            this.delete_traveller_dialog = true
            this.selected_traveller = item
        },
        closeDeleteTravellerDialog() {
            this.delete_traveller_dialog = false
            this.selected_traveller = null
        },
        openCreateNoteDialog() {
            this.create_note_dialog = true
        },
        closeCreateNoteDialog() {
            this.create_note_dialog = false
        },
        openUpdateNoteDialog(item) {
            this.update_note_dialog = true
            this.selected_note = item
        },
        closeUpdateNoteDialog() {
            this.update_note_dialog = false
            this.selected_note = null
        },
        openDeleteNoteDialog(item) {
            this.delete_note_dialog = true
            this.selected_note = item
        },
        closeDeleteNoteDialog() {
            this.delete_note_dialog = false
            this.selected_note = null
        },
        copyPassword() {
            navigator.clipboard.writeText(this.itinerary.password)
            this.$toast.success('Copied to clipboard')
        },
        async setOrganiser(traveller) {
            await this.$axios.post(`v2/itineraries/update_itinerary_traveller_organiser/${traveller.id}/${this.itinerary.id}`)
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.refreshItinerary()
                    }
                })
        },
    },
}
</script>

<style scoped>
.access-label {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 12px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
    margin-right: 5px;
}
</style>
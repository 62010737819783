<template>
  <v-container fluid style="background-color: #f4f4f5 !important; height: 100%" class="pa-0 ma-0">
    <v-skeleton-loader v-if="loading" type="table" />
    <v-row v-else>
      <v-col cols="12" class="ma-0 pa-0">
        <div class="header pt-2">
          <strong class="white--text">
            <label @click="viewSupplier()" style="color:white;cursor:pointer;">
              {{ supplier.supplier_name }}
            </label>
            >
            {{ product.name }}
          </strong>
        </div>
        <v-tabs centered dark background-color="#588BAD" v-model="tab">
          <v-tab href="#tab-1" class="tabs"> Setup </v-tab>
          <!-- <v-tab href="#tab-2" class="tabs"> Locations & Times </v-tab> -->
          <!-- <v-tab href="#tab-3" class="tabs"> Media </v-tab> -->
          <!-- <v-tab href="#tab-4" class="tabs"> Amenities </v-tab> -->
          <!-- <v-tab href="#tab-5" class="tabs"> Inclusions </v-tab> -->
          <v-tab href="#tab-6" class="tabs"> Options </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <Setup :supplier="supplier" :product="product" @getSupplier="getSupplier" />
          </v-tab-item>
          <v-tab-item value="tab-2">
            <Location></Location>
            <Time></Time>
          </v-tab-item>
          <!-- <v-tab-item value="tab-3">
          <Media />
        </v-tab-item> -->
          <v-tab-item value="tab-4">
            <Amenity />
          </v-tab-item>
          <v-tab-item value="tab-5">
            <Inclusion />
          </v-tab-item>
          <v-tab-item value="tab-6">
            <Option :supplier="supplier" :product="product" @getSupplier="getSupplier" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Setup from './product/ProductSetup.vue'
import Location from './product/ProductLocation.vue'
import Time from './product/ProductTime.vue'
// import Media from './product/ProductMedia.vue'
import Amenity from './product/ProductAmenity.vue'
import Inclusion from './product/ProductInclusion.vue'
import Option from './product/ProductOption.vue'

export default {
  components: { Setup, Location, Time, Amenity, Inclusion, Option },
  props: [],
  data: () => ({
    loading: true,
    tab: null,
    supplier_id: null,
    supplier: null,
    product_id: null,
    product: null,
  }),
  mounted() {
    this.supplier_id = parseInt(this.$route.params.supplier_id)
    this.product_id = parseInt(this.$route.params.product_id)
    this.getSupplier()
  },
  created() { },
  computed: {
  },
  methods: {
    async getSupplier() {
      await this.$axios.get(`v2/suppliers/get_supplier/${this.supplier_id}`)
        .then(({ data }) => {
          if (data.response) {
            this.supplier = data.data
            this.product = data.data.products.filter(x => x.id == this.product_id)[0]
            console.log(this.product)
            this.loading = false
          }
        })
    },
    viewSupplier() {
      this.$router.push({ name: 'View Supplier', params: { id: this.supplier_id } })
    },
  },
  watch: {},
}
</script>

<style scoped lang="scss">
.header {
  max-width: 100%;
  background-color: #79a2bd;
  height: 50px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 50px;
}

.tabs {
  text-transform: none !important;
}
</style>

<template>
    <v-container fluid style="background-color: #F4F4F5 !important; height: 100%;" class="pa-0 ma-0">
        <v-app-bar app clipped-left dark color="#447fa6" elevation="0">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-app-bar>
        <v-card elevation="12" width="256">
            <v-navigation-drawer floating app :permanent="!$vuetify.breakpoint.mdAndDown" v-model="drawer"
                color="#588BAD" class="no-border shadow">
                <v-card-title style="color: white;">
                    PlanMyTour
                </v-card-title>
                <v-card-text style="color: white !important;">
                    <v-list-item two-line style="cursor: pointer;" @click="openSelectCompanyDialog">
                        <v-avatar color="grey" size="30" class="mr-2">
                            {{ get_user.name[0] }}
                        </v-avatar>
                        <v-list-item-content>
                            <v-list-item-title style="font-size: 14px; color: white;">
                                {{ get_user.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="get_selected_company.id" style="font-size: 12px; color: white;">
                                {{ get_selected_company.company_name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else style="font-size: 12px; color: white;">
                                Please select a company
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card-text>
                <v-divider></v-divider>
                <v-list dense rounded v-if="get_selected_company.id">
                    <v-list-item @click="$router.push(item.url)" v-for="(item, itemindex) in navigation"
                        :key="itemindex" link>
                        <v-list-item-icon>
                            <v-icon :style="item.style">{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :style="item.style">{{ item.label }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="$router.push({ name: 'Suppliers' })"
                        v-if="get_user.user_role_id == 1 || get_user.access_supplier == 1" link>
                        <v-list-item-icon>
                            <v-icon style="color:white;">mdi-account-box-multiple-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title style="color:white;">Suppliers</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <template v-slot:append>
                    <div>
                        <v-list v-if="get_user.user_role_id == 1" dense rounded>
                            <h4 style="color:white;">Agency Settings</h4>
                            <v-list-item v-for="(item, itemindex) in navigation2" :key="itemindex"
                                @click="$router.push(item.url)" link>
                                <v-list-item-icon>
                                    <v-icon :style="item.style">{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title :style="item.style">{{ item.label }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-list dense rounded>
                            <v-list-item @click="logout()" link>
                                <v-list-item-icon>
                                    <v-icon style="color:white;">mdi-logout</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title style="color:white;">Logout</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                </template>
            </v-navigation-drawer>
        </v-card>
        <main class="main">
            <slot />
        </main>
        <v-row justify="center">
            <v-dialog max-width="620" v-model="select_company_dialog" persistent>
                <v-card v-if="accesses">
                    <v-card-title>
                        Choose Company
                        <v-spacer></v-spacer>
                        <v-icon @click="closeSelectCompanyDialog()">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-autocomplete v-model="company_selected"
                            :items="get_companies.filter(company => accesses.includes(company.id))" item-value="id"
                            item-text="company_name" label="Choose a company to work with today" outlined dense
                            return-object>
                        </v-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="selectCompany()" block dark color="#588BAD" rounded>
                            Select
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    data: () => ({
        navigation: [
            {
                icon: 'mdi-home-outline',
                label: 'Home',
                style: 'color: white',
                url: {
                    name: 'Dashboard'
                }
            },
            {
                icon: 'mdi-clipboard-list-outline',
                label: 'Itineraries',
                style: 'color: white',
                url: {
                    name: 'Itineraries'
                }
            },
            {
                icon: 'mdi-book-clock',
                label: 'Bookings',
                style: 'color: white',
                url: {
                    name: 'Bookings'
                }
            },
            {
                icon: 'mdi-folder-multiple-outline',
                label: 'Resources',
                style: 'color: white',
                url: {
                    name: 'Resources'
                }
            },
            {
                icon: 'mdi-account-group-outline',
                label: 'Contacts',
                style: 'color: white',
                url: {
                    name: 'Contacts'
                }
            },
            {
                icon: 'mdi-domain',
                label: 'Agencies',
                style: 'color: white',
                url: {
                    name: 'Agencies'
                }
            },
            {
                icon: 'mdi-file-tree-outline',
                label: 'Tasks',
                style: 'color: white',
                url: {
                    name: 'Tasks'
                }
            },
        ],
        navigation2: [
            {
                icon: 'mdi-cog-outline',
                label: 'Companies',
                style: 'color: white',
                url: {
                    name: 'Companies'
                }
            },
            {
                icon: 'mdi-account-group-outline',
                label: 'Users',
                style: 'color: white',
                url: {
                    name: 'Users'
                }
            },
            {
                icon: 'mdi-calendar',
                label: 'Reminders',
                style: 'color: white',
                url: {
                    name: 'Reminders'
                }
            },
        ],
        select_company_dialog: false,
        company_selected: null,
        drawer: false,
        accesses: null,
    }),
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_companies: 'itineraryV2/get_companies',
            get_selected_company: 'auth/get_selected_company'
        })
    },
    async mounted() {
        if (this.get_selected_company.id == null) {
            this.openSelectCompanyDialog()
        }
        this.accesses = this.get_user.accesses.map(access => access.company_id)
    },
    methods: {
        openSelectCompanyDialog() {
            this.select_company_dialog = true
        },
        closeSelectCompanyDialog() {
            this.select_company_dialog = false
        },
        selectCompany() {
            if (this.company_selected == null)
                return
            this.$store.dispatch('auth/set_selected_company', this.company_selected)
            this.closeSelectCompanyDialog()
            this.$router.go()
        },
        async logout() {
            await this.$axios.post('/logout')
            this.$store.commit('auth/set_empty_data')
            this.$router.push({ name: 'Login' })
        }
    }
}
</script>
<style scoped></style>
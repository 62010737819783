<template>
    <v-container>
        <v-dialog v-model="create_traveller_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>Add Traveller</v-card-title>
                <v-card-text>
                    <v-text-field v-model="new_traveller.first_name" label="First Name *" outlined></v-text-field>
                    <v-text-field v-model="new_traveller.last_name" label="Last Name" outlined></v-text-field>
                    <v-text-field v-model="new_traveller.email" label="Email" outlined></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeCreateTravellerDialog()" text>Cancel</v-btn>
                    <v-btn @click="createTraveller()" color="primary">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="update_traveller_dialog" persistent max-width="600">
            <v-card v-if="update_traveller_dialog">
                <v-card-title>Update Traveller</v-card-title>
                <v-card-text>
                    <v-text-field v-model="selected_traveller.first_name" label="First Name *" outlined></v-text-field>
                    <v-text-field v-model="selected_traveller.last_name" label="Last Name" outlined></v-text-field>
                    <v-text-field v-model="selected_traveller.email" label="Email" outlined></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeUpdateTravellerDialog()" text>Cancel</v-btn>
                    <v-btn @click="updateTraveller()" color="primary">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="delete_traveller_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>
                    Are you sure you want to remove this traveller?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteTravellerDialog()" text>Cancel</v-btn>
                    <v-btn @click="deleteTraveller()" color="red" dark>Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'CreateTravellerDialog',
    components: {
    },
    props: [
        'create_traveller_dialog', 'update_traveller_dialog', 'delete_traveller_dialog', 'itinerary', 'selected_traveller'
    ],
    data: () => ({
        new_traveller: {
            first_name: null,
            last_name: null,
            email: null,
            mobile_no: null,
            date_of_birth: null,
            special_requests: null,
            emergency_contact: null,
            accessibility_needs: null,
            medical_info: null,
            nationality: null,
            home_address: null,
            home_country: null,
            flight_date: null,
            flight_number: null,
            arrival_time: null,
            departure_time: null,
            airport: null,
        },
    }),
    async mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        closeCreateTravellerDialog() {
            this.$emit('closeCreateTravellerDialog')
            this.new_traveller = {
                first_name: null,
                last_name: null,
                email: null,
                mobile_no: null,
                date_of_birth: null,
                special_requests: null,
                emergency_contact: null,
                accessibility_needs: null,
                medical_info: null,
                nationality: null,
                home_address: null,
                home_country: null,
                flight_date: null,
                flight_number: null,
                arrival_time: null,
                departure_time: null,
                airport: null,
            }
        },
        async createTraveller() {
            let payload = {
                ...this.new_traveller,
                itinerary_id: this.itinerary.id,
                password: this.itinerary.password,
                is_organiser: false,
            }
            if (this.itinerary.travellers.length == 0) {
                payload.is_organiser = true
            }
            await this.$axios.post('v2/itineraries/create_itinerary_traveller', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateTravellerDialog()
                        this.$emit('refreshItinerary')
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        closeUpdateTravellerDialog() {
            this.$emit('closeUpdateTravellerDialog')
        },
        async updateTraveller() {
            let payload = {
                first_name: this.selected_traveller.first_name,
                last_name: this.selected_traveller.last_name,
                email: this.selected_traveller.email,
            }
            await this.$axios.post(`v2/itineraries/update_itinerary_traveller/${this.selected_traveller.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateTravellerDialog()
                        this.$emit('refreshItinerary')
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        closeDeleteTravellerDialog() {
            this.$emit('closeDeleteTravellerDialog')
        },
        async deleteTraveller() {
            await this.$axios.delete(`v2/itineraries/delete_itinerary_traveller/${this.selected_traveller.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteTravellerDialog()
                        this.$emit('refreshItinerary')
                    }
                })
        },
    },
}
</script>
<template>
    <v-dialog :value="create_itinerary_dialog" width="600" persistent>
        <v-card>
            <v-card-title>
                Create New Itinerary
                <v-spacer></v-spacer>
                <v-btn @click="closeCreateItineraryDialog()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="12" class="px-2">
                        <v-text-field v-model="itinerary.itinerary_name" label="Itinerary name *"
                            outlined></v-text-field>
                    </v-col>
                    <v-col cols="6" class="px-2">
                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="5" :nudge-bottom="61"
                            transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="itinerary.start_date" label="Start date *" readonly
                                    v-bind="attrs" v-on="on" outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="itinerary.start_date"
                                @input="menu1 = false; date_changed = true;"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6" class="px-2">
                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="5" :nudge-bottom="61"
                            transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="itinerary.end_date" label="End date *" readonly v-bind="attrs"
                                    v-on="on" outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="itinerary.end_date"
                                @input="menu2 = false; date_changed = true;"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6" class="px-2">
                        <v-select :items="get_companies" v-model="get_selected_company" disabled
                            item-text="company_name" item-value="id" outlined label="Company *"></v-select>
                    </v-col>
                    <v-col cols="6" class="px-2">
                        <v-select v-model="itinerary.currency_id" :items="get_currencies" item-text="code" item-value="id" label="Currency *" outlined></v-select>
                    </v-col>
                    <v-col cols="6" class="px-2">
                        <v-text-field v-model="itinerary.travellers_number" label="Initial number of travellers"
                            outlined></v-text-field>
                    </v-col>
                    <v-col cols="6" class="px-2">
                        <v-select v-model="itinerary.tour_type_id" :items="get_tour_types" item-value="id"
                            item-text="name" label="Tour Type" outlined></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="closeCreateItineraryDialog()" text rounded>Cancel</v-btn>
                <v-btn @click="createItinerary()" v-if="!creating" rounded color="primary" class="px-5">
                    Create
                </v-btn>
                <v-btn v-else rounded color="primary" disabled class="px-5">
                    Please wait
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'CreateItineraryDialog',
    components: {
    },
    props: [
        'create_itinerary_dialog',
    ],
    data: () => ({
        menu1: false,
        menu2: false,
        itinerary: {
            itinerary_name: null,
            start_date: null,
            end_date: null,
            currency_id: 1, // Pull default from company currency_id
            payment_currency_id: 1,
            travellers_number: 1,
            itinerary_status_id: 1,
            itinerary_template_id: 1,
            itinerary_map_type: 1,
            tour_type_id: null,
        },
        creating: false,
    }),
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'auth/get_selected_company',
            get_companies: 'itineraryV2/get_companies',
            get_statuses: 'itineraryV2/get_statuses',
            get_templates: 'itineraryV2/get_templates',
            get_tour_types: 'itineraryV2/get_tour_types',
            get_invoice_statuses: 'itineraryV2/get_invoice_statuses',
            get_stages: 'itineraryV2/get_stages',
            get_currencies: 'itineraryV2/get_currencies',
        }),
    },
    async mounted() {
    },
    methods: {
        async createItinerary() {
            if (this.$date(this.itinerary.end_date).isBefore(this.$date(this.itinerary.start_date)) || this.$date(this.itinerary.end_date).isSame(this.$date(this.itinerary.start_date))) {
                this.$toast.error('End date must be greater than the start date')
                return
            }
            this.creating = true
            this.itinerary.payment_currency_id = this.itinerary.currency_id
            let payload = {
                ...this.itinerary,
                company_id: this.get_selected_company.id,
                created_by: this.get_user.id,
            }
            await this.$axios.post('v2/itineraries/create_itinerary', payload)
                .then(({ data }) => {
                    this.creating = false
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.$router.push(`/itinerary/update/${data.data.id}`)
                        this.closeCreateItineraryDialog()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        closeCreateItineraryDialog() {
            this.$emit('closeCreateItineraryDialog')
            this.itinerary = {
                itinerary_name: null,
                start_date: null,
                end_date: null,
                currency_id: 1,
                payment_currency_id: 1,
                travellers_number: 1,
                itinerary_status_id: 1,
                itinerary_template_id: 1,
                itinerary_map_type: 1,
                tour_type_id: null,
            }
        },
    },
}
</script>
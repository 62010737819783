import Supplier from '../SupplierListComponent.vue'
import CreateSupplier from '../CreateSupplier.vue'
import ViewSupplier from '../components/setup/SupplierComponent.vue'
import ViewProduct from '../components/setup/pages/SupplierProductSetupComponent.vue'

export default [
  {
    path: '/supplier',
    name: 'Suppliers',
    component: Supplier,
  },
  {
    path: '/create-supplier',
    name: '/create-supplier',
    component: CreateSupplier,
  },
  {
    path: '/view-supplier/:supplier_id',
    name: 'View Supplier',
    component: ViewSupplier,
  },
  {
    path: '/view-supplier/:supplier_id/product/:product_id',
    name: 'View Product',
    component: ViewProduct,
  },
]
<template>
    <v-container>
        <v-skeleton-loader type="card" v-if="loading_bookings"></v-skeleton-loader>
        <v-card v-else-if="get_user.id == 98" flat>
            <v-tabs v-model="tab" fixed-tabs>
                <v-tab href="#tab-1">Payments</v-tab>
                <v-tab href="#tab-2">Invoices</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                    <v-card flat>
                        <Payments :itinerary="itinerary" :net="net" :gross="gross" @refreshBookings="refreshBookings" />
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-2">
                    <v-card flat>
                        <Invoices :itinerary="itinerary" :net="net" :gross="gross" @refreshBookings="refreshBookings" />
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <v-card v-else>
            <Invoices :itinerary="itinerary" :net="net" :gross="gross" @refreshBookings="refreshBookings" />
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Payments from './payments/Payments.vue'
import Invoices from './invoices/Invoices.vue'

export default {
    name: 'PaymentComponent',
    components: {
        Payments,
        Invoices,
    },
    props: [
        'itinerary', 'loading_bookings', 'net', 'gross'
    ],
    data: () => ({
        tab: null,
    }),
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
        }),
    },
    async mounted() {
    },
    methods: {
        async refreshBookings() {
            this.$emit('refreshBookings', true)
        },
    },
}
</script>

<style scoped></style>
